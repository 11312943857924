import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import Swal from "sweetalert2";
import '../../style/formClient.css'
import { getDataLeadOwner } from '../../services/configLeadOwner';
import { getDataLeadStatus } from '../../services/dynamicList/leadStatus';
import { getDataLeadSource } from '../../services/dynamicList/leadSource';
import { VerifyClientExists } from "../../services/crm/clients";
import { FaInfoCircle } from "react-icons/fa";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { formatDate } from '../../services/Tools';
import { FaCheck } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';

function FormClient({ title, textButton, action, lastData, setModal }) {
    
    let day = new Date();

    const [id, setId] = useState("");
    const [client, setClient] = useState("");
    const [lastName, setLastName] = useState("");
    const [dataClient, setDataClient] = useState({ rows: [] });
    const [pending, setPending] = useState(true);
    const [exist, setExits] = useState(false);
    const [organisation, setOrganisation] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    // const [source, setSource] = useState("");
    const [owner, setOwner] = useState({ value: '', label: '' });
    const [date, setDate] = useState(formatDate(day, "yyyy-MM-dd"));
    const [modified, setModified] = useState("");
    const [historical, setHistorical] = useState([]);
    const navigate = useNavigate();

    // This is necessary to fill in the selects and reuse the code from the config lead owner services.
    const [leadOwnerListAux, setLeadOwnerListAux] = useState([]);
    const [leadOwnerList, setLeadOwnerList] = useState([{ value: '', label: '' }]);
    
    const [leadStatus, setLeadStatus] = useState({ value: '', label: '' });
    const [leadStatusListAux, setLeadStatusListAux] = useState([]);
    const [leadStatusList, setLeadStatusList] = useState([{ value: '', label: '' }]);

    const [leadSource, setLeadSource] = useState({ value: '', label: '' });
    const [leadSourceListAux, setLeadSourceListAux] = useState([]);
    const [leadSourceList, setLeadSourceList] = useState([{ value: '', label: '' }]);

    //Use to get the different Lists
    useEffect(() => {
        getDataLeadOwner(setLeadOwnerListAux);
        getDataLeadStatus(setLeadStatusListAux);
        getDataLeadSource(setLeadSourceListAux);
    }, []);

    //Use by Lead Owner List
    useEffect(() => {
        if (leadOwnerListAux?.rows?.length > 0) {
            var aux = [];
            leadOwnerListAux?.rows?.forEach(element => {
                aux.push({ value: element.value, label: element.label });
            });
            setLeadOwnerList(aux);
        }
    }, [leadOwnerListAux]);

    //Use by lead status
    useEffect(() => {
        if (leadStatusListAux?.rows?.length > 0) {
            var aux = [];
            leadStatusListAux?.rows?.forEach(element => {
                aux.push({ value: element.value, label: element.label });
            });
            setLeadStatusList(aux);
        }
    }, [leadStatusListAux]);

    //Use by lead source
    useEffect(() => {
        if (leadSourceListAux?.rows?.length > 0) {
            var aux = [];
            leadSourceListAux?.rows?.forEach(element => {
                aux.push({ value: element.value, label: element.label });
            });
            setLeadSourceList(aux);
        }
    }, [leadSourceListAux]);

    useEffect(() => {
        if (lastData) {
            setId(lastData.id ? lastData.id : "");
            setClient(lastData.client ? lastData.client : "");
            setLastName(lastData.lastName ? lastData.lastName : "");
            setOrganisation(lastData.organisation ? lastData.organisation : "")
            setEmail(lastData.email ? lastData.email : "");
            setPhone(lastData.phone ? lastData.phone : "");
            setLeadStatus(lastData?.leadStatus?.value ? { value: lastData?.leadStatus?.value, label: lastData?.leadStatus?.label } : { value: '', label: '' });
            setLeadSource(lastData.leadSource ? lastData.leadSource : { value: '', label: '' });

            //setOwner(lastData.owner.value ? { value: lastData.owner.value, label: lastData.owner.label } : lastData.owner ? { value: lastData.owner, label: lastData.owner } : { value: '', label: '' });
            setOwner(lastData.owner?.value ? { value: lastData.owner.value, label: lastData.owner.label } : { value: lastData.owner || '', label: lastData.owner || '' });

            setDate(lastData.date ? lastData.date : formatDate(day, "yyyy-MM-dd"));
            setModified(lastData.modified ? lastData.modified : "");

            let newHistorical = [];
            for (let i = 0; lastData?.historical_matriz?.length > i; i++) {
                newHistorical[i] = lastData.historical_matriz[i];
            }

            setHistorical(newHistorical);
        }
    }, [lastData])

    const leadStatusHistorical = () => {
        Swal.fire({
            title: '<strong>Historical</strong>',
            icon: 'info',
            html: `                              
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Updated</th>
                <th scope="col">Change by</th>
              </tr>
            </thead>
            <tbody>
              ${getHistorical()}
            </tbody>
          </table>`,
            showCloseButton: true,
            confirmButtonColor: "#173d38"
        })
    }

    const getHistorical = () => {
        if (!historical?.length) {
            return "<tr><td colspan='4'>Without results</td></tr>";
        }
    
        const rows = historical
            .filter(element => element.newValue) // Filtrar elementos que tienen `newValue`
            .map(element => 
                `<tr>
                    <td>${element.newValue}</td>
                    <td>${element.updated}</td>
                    <td>${element.change}</td>
                </tr>`
            )
            .join(""); // Unir todas las filas generadas en una sola cadena
    
        return rows || "<tr><td colspan='4'>Without results</td></tr>";
    };

    const navigateTo = () => {
        //navigate(`/booking/${id}`);
        //window.location.reload(false);
    }

    const searchBooking = () => {
        navigate(`/clients/bookings/${id}`);
        window.location.reload(false);
    }

    const confirmData = (data) => {
        if (client !== '' && email !== '' && leadStatus !== '' && owner.value !== '' && date !== '' && leadSource?.value) {     
            //let exist = null;
            //let register = false;
           if(!lastData){

                //let exist = VerifyClientExists(data?.client.toString());
                //exist.then(function (doc){
                    // if(doc){
                    //     Swal.fire({
                    //         icon: "error",
                    //         title: "Error!",
                    //         text: "The client name is already registered.",
                    //         confirmButtonColor: '#173d38',
                    //         confirmButtonText: "OK",
                    //     });
                    // }else{
                        if( Date.parse(day) < Date.parse(date)){
                            Swal.fire({
                                icon: "error",
                                title: "Error!",
                                text: "The contact date cannot be later than today",
                                confirmButtonColor: '#173d38',
                                confirmButtonText: "OK",
                            });
                        }else{
                            action(data);
                        }
                //     }
                // });
            }else{
                if(lastData?.client !== client){
                    // let exist = VerifyClientExists(client.toString());

                    // exist.then(function (doc){
                    //     if(doc){
                    //         Swal.fire({
                    //             icon: "error",
                    //             title: "Error!",
                    //             text: "The new client name is already registered.",
                    //             confirmButtonColor: '#173d38',
                    //             confirmButtonText: "OK",
                    //         });
                    //     }else{
                            if( Date.parse(day) < Date.parse(date)){
                                Swal.fire({
                                    icon: "error",
                                    title: "Error!",
                                    text: "The contact date cannot be later than today",
                                    confirmButtonColor: '#173d38',
                                    confirmButtonText: "OK",
                                });
                            }else{
                                action(data);
                            }
                    //     }
                    // });                
                }else{
                    if( Date.parse(day) < Date.parse(date)){
                        Swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "The contact date cannot be later than today",
                            confirmButtonColor: '#173d38',
                            confirmButtonText: "OK",
                        });
                    }else{
                        action(data);
                    }
                }
            }
            

        } else {
            Swal.fire({
                icon: "error", //Constants = success, error, warning
                title: "Error!",
                text: "All fields with (*) are required",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
              });
        }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formFullName">
                        <div className="d-inline-flex">
                        { lastData?.id ? ( <Form.Label><BiSearchAlt className={'mb-1 color-green-info  p-0 rounded-circle'} onClick={searchBooking} title="Show the client bookings"></BiSearchAlt>Client name</Form.Label> )
                            : (<Form.Label>Client name</Form.Label>)
                        }                            
                        <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the client name"
                            value={client}
                            onChange={(e) => setClient(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter the last name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formOrganisation">
                        <Form.Label>Organization</Form.Label>
                        <Form.Control type="text" placeholder="Enter Organization"
                            value={organisation}
                            onChange={(e) => setOrganisation(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formLeadOwner">
                        <div className="d-inline-flex">
                            <Form.Label>Lead Owner</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Select options={leadOwnerList} value={owner}
                            onChange={(e) => setOwner(e)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formLeadStatus">
                        <div className="d-inline-flex">
                            <Form.Label>
                                <FaInfoCircle className={'mb-1 color-green-info  p-0 rounded-circle'}
                                onClick={leadStatusHistorical} 
                                data-toggle="tooltip" 
                                data-placement="top" 
                                title="Shows the Lead Status host">
                                </FaInfoCircle>Lead Status
                            </Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Select options={leadStatusList} value={leadStatus}
                            onChange={(e) => setLeadStatus(e)} />
                    </Form.Group>

                </div>
                <div className='col-md-6'>

                    <Form.Group className="mb-3" controlId="formLeadSoruce">

                    <div className="d-inline-flex">
                        <Form.Label>Lead Source</Form.Label>
                        <h5 className="required">*</h5>
                    </div>

                    <Select options={leadSourceList} value={leadSource}
                        onChange={(e) => setLeadSource(e)} />

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                        <div className="d-inline-flex">
                            <Form.Label>Email Address</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Form.Control type="email" placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="text" placeholder="Enter Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formContactDate">
                        <div className="d-inline-flex">
                            <Form.Label>Contact Date</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Form.Control type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </Form.Group>

                    

                </div>
                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {

                    var arrayHistorical = [];
                    let historical_matriz = historical;
                    if(lastData?.leadStatus){
                        if (lastData?.leadStatus?.value !== leadStatus.value) {
                            let today = new Date();

                            // Formatear la fecha en MM/DD/YYYY usando Intl.DateTimeFormat
                            let formattedDate = new Intl.DateTimeFormat('en-US').format(today);

                            // Formatear la hora en HH:MM:SS
                            let formattedTime = today.toLocaleTimeString('en-US', { hour12: false });

                            // Combinar fecha y hora
                            let now = `${formattedDate}, ${formattedTime}`;

                            let newHistorical = {
                                newValue: leadStatus.value,
                                oldValue: lastData.leadStatus,
                                updated: now,
                                change: auth.currentUser.displayName,
                            }

                            //I iterated it like this, because if the iterator is an object, for some reason it throws me an error
                            historical.forEach(element => {
                                arrayHistorical.push(element);
                            });
                            arrayHistorical.push(newHistorical);
                        }
                        historical_matriz = (lastData?.leadStatus?.value !== leadStatus.value) ? arrayHistorical : historical;

                    } else{
                        let today = new Date();

                        // Formatear la fecha en MM/DD/YYYY usando Intl.DateTimeFormat
                        let formattedDate = new Intl.DateTimeFormat('en-US').format(today);
                        
                        // Formatear la hora en HH:MM:SS
                        let formattedTime = today.toLocaleTimeString('en-US', { hour12: false });
                        
                        // Combinar fecha y hora
                        let now = `${formattedDate}, ${formattedTime}`;
                        
                        let newHistorical = {
                            newValue: leadStatus.value,
                            oldValue: "",
                            updated: now, // Guardar fecha y hora formateadas
                            change: auth.currentUser.displayName,
                        };

                            //I iterated it like this, because if the iterator is an object, for some reason it throws me an error
                            historical.forEach(element => {
                                arrayHistorical.push(element);
                            });
                            arrayHistorical.push(newHistorical);
                            historical_matriz = arrayHistorical;
                    }

                    var data = {
                        id,
                        client,
                        lastName,
                        organisation,
                        email,
                        phone,
                        leadStatus,
                        leadSource,
                        owner,
                        date,
                        modified,
                        historical_matriz
                    };

                    confirmData(data);
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormClient;