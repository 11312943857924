import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsPointOfSale } from '../../../services/columnsList';
import { columnsSales } from '../../../services/columnsList';
import Button from '../../button'; 
import ModalC from '../../modal';
import FormConfig from './formConfig';
import Swal from "sweetalert2";
import {  getDataPointOfSale, addDataPointOfSale, editDataPointOfSale, deleteDataPointOfSale } from '../../../services/ConfigPointOfSale';
import {  getDataSales, addDataSales, editDataSale, deleteDataSale } from '../../../services/pointOfSale/sale';
import {  getDataCarts } from '../../../services/pointOfSale/carts';
import { UserAuth } from '../../../context/AuthContext';
import { Input } from 'antd';
import '../../../style/table.css';
import { useNavigate } from 'react-router-dom';

function TableConfig() {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState({ rows: [] });
  const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);
  const [edit, setEdit] = useState(null);
  const [titleModal, setTitleModal] = useState('');
  const [expand, setExpand] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { user } = UserAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (user != null) {
  //     //getDataPointOfSale(setData, setPending);
  //     getDataSales(setData, setPending);
  //   }
  // }, [user]);

  useEffect(() => {
    if (user != null) {
      //getDataPointOfSale(setData, setPending);
      getDataCarts(setData, setPending);
    }
  }, [user]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  // const action = (data) => {
  //   handleClearRows();

  //   if (edit) {
  //     editDataPointOfSale(data);
  //   } else {
  //     addDataPointOfSale(data, true);
  //   }

  //   setModal(false);
  //   setTimeout(() => {
  //     //getDataPointOfSale(setData);
  //     getDataSales(setData);

  //   }, 500);
  // }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const editObj = (id) => {
    let aux = null;
    filteredItems.forEach(element => {
      if (element.id === id) {
        aux = element;
      }
    });
    navigate(`/sale/${aux?.id}`);
  }

  const deleteObj = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#173d38',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let aux = null;
        filteredItems.forEach(element => {
          if (element.id === id) {
            aux = element;
          }
        });
        //deleteDataPointOfSale(aux);
        Swal.fire({
          title: 'Deleted!',
          text: 'Has been removed successfully.',
          icon: 'success',
          confirmButtonColor: '#173d38',
        })
        setTimeout(() => {
          setModal(false);
          //getDataPointOfSale(setData);
          //getDataSales(setData);
          getDataCarts(setData);
          handleClearRows();
        }, 1000)
      }
    })
  }

  const filteredItems = data?.rows?.filter(
    item => item.created_by_name && item.created_by_name.toLowerCase().includes(filterText.toLowerCase()) ||
    item.id && item.id.toLowerCase().includes(filterText.toLowerCase()) );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="content">
      <div className="d-flex justify-content-between flex-row-reverse ">
        <div className="gap-2 m-3">
          {/* <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => {
            setModal(true);
            setTitleModal('Add new sale');
            setEdit(null);
          }} >+Add sale</Button> */}
          <a className="color-green btn btn-outline-secondary p-2 mx-2" href='/sale'>+Add new sale</a>
        </div>
      </div>
      <div className="m-3">
        <DataTable
          columns={columnsSales(editObj, deleteObj)}
          data={filteredItems}
          selectableRows
          onSelectedRowsChange={handleChange}
          pagination
          clearSelectedRows={toggledClearRows}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          progressPending={pending}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
        {/* <ModalC
          show={modal}
          onHide={() => setModal(false)}>
          
          <FormConfig title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModal}></FormConfig>
        </ModalC> */}
      </div>
    </div>
  );
}

export default TableConfig;
