import { collection, onSnapshot } from "firebase/firestore";
import { toast } from "react-toastify"; 
import { auth, db, dbCrm } from "../firebase";
import { server } from './serverName/Bookings';
import { getDataItineraryById } from '../services/localStorage/storage';
import { privateCharter } from '../services/Tools';
import { addDataNotifications, deleteDataNotifications, getOneNotificationData } from '../services/ConfigNotifications';

// Esta función inicializa los listeners en varias colecciones
export const initializeBookingListener = (callback) => {
    if (auth.currentUser != null) { // Verifica si el usuario está autenticado
        const bookingsCollection = collection(db, server + '_reservations');
        const adventuresCollection = collection(db, server + '_adventures');
        const clientsCollection = collection(dbCrm, server);
        const rentalsCollection = collection(db, server + '_rentals');

        // Listener para bookings
        const unsubscribeBookings = onSnapshot(bookingsCollection, (snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
                const bookingData = change.doc.data();
                const currentDate = new Date();
                const futureDate = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000); // 14 días adelante
                const pastDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 días atrás
                const todayDate = new Date(currentDate.getTime() - 2 * 24 * 60 * 60 * 1000); // 2 días atrás
                const startDate = new Date(bookingData?.startDate || 0);
                const createdDate = bookingData?.created_date ? new Date(bookingData.created_date.seconds * 1000) : new Date(0);
                const addNotification = bookingData?.notificationAfter || false;

                if (startDate < pastDate || startDate > futureDate) return;

                //const truncateText = (text) => (text?.length > 20 ? `${text.slice(0, 16)}...` : text);
                const clientName = truncateText(bookingData?.clientName || bookingData?.newClientData);
                const idBooking = `${bookingData.id}-booking`;

                // Procesar balance pendiente
                if (bookingData.balance > 0) {

                    const data = {
                        idService: bookingData.id,
                        title: `Balance due`,
                        message: `${clientName} is owing $${bookingData.balance}`,
                        type: `booking`,
                        postponed: false,
                        postponed_date: ``,
                    };

                    const shouldAdd = createdDate <= todayDate && !addNotification;
                    await addOrDeleteNotification(idBooking, data, shouldAdd); 

                    // try {
                    //     const currentData = await fetchCurrentNotificationData(idBooking);
                
                    //     let data = {
                    //         idService: bookingData?.id,
                    //         title: `Balance due`,
                    //         message: `${clientName} is owing $${bookingData?.balance}`,
                    //         type: `booking`,
                    //         postponed: currentData.postponed ?? false, // Mantén el valor actual o usa false por defecto.
                    //         postponed_date: currentData.postponed_date ?? "", // Mantén el valor actual o usa una cadena vacía por defecto.
                    //     };
                
                    //     addDataNotifications(idBooking, data, false, false);

                    // } catch (error) {
                    //     console.error("Error al crear la notificación:", error);
                    // }
                }else{
                    await addOrDeleteNotification(idBooking, {}, false);
                }

                // Verificar itinerario para viajes privados
                if (bookingData?.shuttle?.label === privateCharter) {
                    const auxItinerary = await getDataItineraryById(bookingData.id);
                    const idItinerary = `${bookingData.id}-booking-itinerary`;
                    const data = {
                        idService: bookingData.id,
                        title: `Unrecorded itinerary`,
                        message: `${clientName}'s unrecorded itinerary.`,
                        type: `booking-itinerary`,
                        postponed: false,
                        postponed_date: ``,
                    };
                    const shouldAdd = createdDate <= todayDate && auxItinerary?.length === 0 && !addNotification;
                    await addOrDeleteNotification(idItinerary, data, shouldAdd);

                    // try {
                    //     const currentData = await fetchCurrentNotificationData(idItinerary);
                
                    //     let data = {
                    //         idService: bookingData?.id,
                    //         title: `Unrecorded itinerary`,
                    //         message: `${clientName}'s unrecorded itinerary.`,
                    //         type: `booking-itinerary`,
                    //         postponed: currentData.postponed ?? false, // Mantén el valor actual o usa false por defecto.
                    //         postponed_date: currentData.postponed_date ?? "", // Mantén el valor actual o usa una cadena vacía por defecto.
                    //     };
                
                    //     addDataNotifications(idItinerary, data, false, false);

                    // } catch (error) {
                    //     console.error("Error al crear la notificación:", error);
                    // }
                }

                // Procesar depósito no pagado
                const total = bookingData?.total || 0;
                const amountPaid = bookingData?.amountPaid || 0;
                if (total > 0) {
                    const percentagePaid = (amountPaid / total) * 100;
                    const idDeposit = `${bookingData.id}-booking-no-deposit`;
                    const data = {
                        idService: bookingData.id,
                        title: `Deposit due`,
                        message: `${clientName} unpaid deposit.`,
                        type: `booking-no-deposit`,
                        postponed: false,
                        postponed_date: ``,
                    };
                    const shouldAdd = createdDate <= todayDate && percentagePaid < 50 && !addNotification;
                    await addOrDeleteNotification(idDeposit, data, shouldAdd);

                    // try {
                    //     const currentData = await fetchCurrentNotificationData(idDeposit);
                
                    //     let data = {
                    //         idService: bookingData?.id,
                    //         title: `Deposit due`,
                    //         message: `${clientName} unpaid deposit.`,
                    //         type: `booking-no-deposit`,
                    //         postponed: currentData.postponed ?? false, // Mantén el valor actual o usa false por defecto.
                    //         postponed_date: currentData.postponed_date ?? "", // Mantén el valor actual o usa una cadena vacía por defecto.
                    //     };
                
                    //     addDataNotifications(idDeposit, data, false, false);
                    // } catch (error) {
                    //     console.error("Error al crear la notificación:", error);
                    // }
                }
            });
        });

        //Listener para adventures
        const unsubscribeAdventures = onSnapshot(adventuresCollection, (snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
                const adventureData = change.doc.data();
                const currentDate = new Date();
                const futureDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 días adelante
                const pastDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 días atrás
                const todayDate = new Date(currentDate.getTime() - 2 * 24 * 60 * 60 * 1000); // 2 días atrás
                const startDate = new Date(adventureData?.startDate || 0);
                const createdDate = adventureData?.created_date ? new Date(adventureData.created_date.seconds * 1000) : new Date(0);
                const addNotification = adventureData?.notificationAfter || false;
        
                if (startDate < pastDate || startDate > futureDate) return;
        
                //const truncateText = (text) => (text?.length > 20 ? `${text.slice(0, 16)}...` : text);
                const clientName = truncateText(adventureData?.clientName || adventureData?.newClientData);
        
                // const addOrDeleteNotification = async (id, data, shouldAdd) => {
                //     if (shouldAdd) {
                //         addDataNotifications(id, data, false, false);
                //     } else {
                //         deleteDataNotifications(id);
                //     }
                // };
        
                // Procesar balance pendiente
                const idAdventure = `${adventureData.id}-adventure`;
                if (adventureData.balance > 0) {
                    const data = {
                        idService: adventureData.id,
                        title: `Balance due`,
                        message: `${clientName} is owing $${adventureData.balance}`,
                        type: `adventure`,
                        postponed: false,
                        postponed_date: ``,
                    };
                    const shouldAdd = createdDate <= todayDate && !addNotification;
                    await addOrDeleteNotification(idAdventure, data, shouldAdd);

                    // try {
                    //     const currentData = await fetchCurrentNotificationData(idAdventure);
                
                    //     let data = {
                    //         idService: adventureData?.id,
                    //         title: `Balance due`,
                    //         message: `${clientName} is owing $${adventureData?.balance}`,
                    //         type: `adventure`,
                    //         postponed: currentData.postponed ?? false, // Mantén el valor actual o usa false por defecto.
                    //         postponed_date: currentData.postponed_date ?? "", // Mantén el valor actual o usa una cadena vacía por defecto.
                    //     };
                
                    //     addDataNotifications(idAdventure, data, false, false);
                    // } catch (error) {
                    //     console.error("Error al crear la notificación:", error);
                    // } 
                }else{
                    await addOrDeleteNotification(idAdventure, {}, false);
                }
        
                // Procesar depósito no pagado
                const total = adventureData?.total || 0;
                const amountPaid = adventureData?.amountPaid || 0;
                if (total > 0) {
                    const percentagePaid = (amountPaid / total) * 100;
                    const idDeposit = `${adventureData.id}-adventure-no-deposit`;
                    const data = {
                        idService: adventureData.id,
                        title: `Deposit due`,
                        message: `${clientName} unpaid deposit.`,
                        type: `adventure-no-deposit`,
                        postponed: false,
                        postponed_date: ``,
                    };
                    const shouldAdd = createdDate <= todayDate && percentagePaid < 50 && !addNotification;
                    await addOrDeleteNotification(idDeposit, data, shouldAdd);

                    // try {
                    //     const currentData = await fetchCurrentNotificationData(idDeposit);
                
                    //     let data = {
                    //         idService: adventureData?.id, 
                    //         title: `Deposit due`,
                    //         message: `${clientName} unpaid deposit.`,
                    //         type: `adventure-no-deposit`,
                    //         postponed: currentData.postponed ?? false, // Mantén el valor actual o usa false por defecto.
                    //         postponed_date: currentData.postponed_date ?? "", // Mantén el valor actual o usa una cadena vacía por defecto.
                    //     };
                
                    //     addDataNotifications(idDeposit, data, false, false);
                    // } catch (error) {
                    //     console.error("Error al crear la notificación:", error);
                    // }
                }
            });
        });
        
        // Listener para clientes
        const unsubscribeClients = onSnapshot(clientsCollection, (snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
                try {
                    const clientsData = change.doc.data();
                    if (!clientsData?.historical_matriz?.length || !clientsData?.id) {
                        console.warn("Datos incompletos para cliente:", clientsData);
                        return;
                    }

                    const historicalMatriz = clientsData.historical_matriz;
                    const lastStatusString = historicalMatriz[historicalMatriz.length - 1].newValue;
                    const idNotification = `${clientsData.id}-client`;

                    if (lastStatusString !== "Completed") {
                        await handleNotification(idNotification, {}, false);
                        return;
                    }

                    const lastUpdateString = historicalMatriz[historicalMatriz.length - 1]?.updated;
                    const lastUpdate = convertDate(lastUpdateString);

                    if (!lastUpdate || isNaN(lastUpdate.getTime())) {
                        console.error(`Formato de fecha inválido para lastUpdate: ${lastUpdateString}`);
                        return;
                    }

                    if (lastUpdate < ninetyDaysAgo) {
                        const data = generateClientMessage(clientsData.client || "");
                        await handleNotification(idNotification, data, true);
                    } else {
                        await handleNotification(idNotification, {}, false);
                    }
                } catch (error) {
                    console.error("Error procesando cambios del cliente:", error);
                }
            });
        });

        // Listener para rentals
        // const unsubscribeRentals = onSnapshot(rentalsCollection, (snapshot) => {
        //     snapshot.docChanges().forEach((change) => {
        //         if (change.type === "added") {
        //             const rentalData = change.doc.data();
        //             if (rentalData.balance > 0) {
        //                 callback({ ...rentalData, type: "rental" });
        //                 // toast.warning(`New rental added with a balance of $${rentalData.balance}`);
        //             }
        //         }
        //     });
        // });

        // Retornar una función que limpie todos los listeners al mismo tiempo
        return () => {
            unsubscribeBookings();
            unsubscribeAdventures();
            unsubscribeClients();
            // unsubscribeRentals();
        };
    }
};

const fetchCurrentNotificationData = async (idDeposit) => {
    try {
        // Obtener datos existentes desde la base de datos
        const existingData = await getOneNotificationData(idDeposit); // Implementa esta función para acceder a tu base de datos.
        return existingData || {};
    } catch (error) {
        console.error("Error al obtener los datos existentes:", error);
        return {};
    }
};

// Función para truncar texto reutilizable
const truncateText = (text, maxLength = 20) =>
    text?.length > maxLength ? `${text.slice(0, maxLength - 4)}...` : text;
  
// Calcular fecha límite una sola vez
const ninetyDaysAgo = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
  

function convertDate(inputDate) {
    if (!inputDate || typeof inputDate !== "string") {
        throw new Error("La fecha proporcionada no es válida.");
    }

    try {
        // Separar fecha y hora
        const [datePart, timePart] = inputDate.split(", ");
        if (!datePart || !timePart) {
            throw new Error("Formato de fecha y hora inválido.");
        }

        let [month, day, year] = datePart.split("/");

        // Normalizar día y mes para que tengan dos dígitos
        day = day.padStart(2, "0");   
        month = month.padStart(2, "0");

        // Convertir timePart (hora) de formato 12 horas a formato 24 horas (militar)
        const time12h = timePart.trim(); // Eliminar cualquier espacio adicional
        const [time, period] = time12h.split(" "); // Separar hora de AM/PM
        const [hours, minutes, seconds] = time.split(":"); // Separar hora, minutos y segundos

        let hours24 = parseInt(hours, 10);

        // Convertir AM/PM a formato 24 horas
        if (period === "PM" && hours24 < 12) {
            hours24 += 12; // Convertir PM (excepto 12 PM)
        } else if (period === "AM" && hours24 === 12) {
            hours24 = 0; // Convertir 12 AM a 00 (medianoche)
        }

        // Normalizar la hora a dos dígitos
        const formattedTime = `${hours24.toString().padStart(2, "0")}:${minutes}:${seconds}`;

        // Crear un objeto Date válido con la fecha y la hora en formato 24 horas
        const formattedDate = new Date(`${year}-${month}-${day}T${formattedTime}`);

        if (isNaN(formattedDate)) {
            throw new Error("Fecha resultante no válida.");
        }

        return formattedDate; // Retorna como objeto Date
    } catch (error) {
        console.error("Error al convertir la fecha:", error.message);
        throw error; // Relanzar el error para manejo en capas superiores
    }
}

// Función para manejar notificaciones
async function handleNotification(id, data, add = true) {
    try {
        await addOrDeleteNotification(id, data, add);
    } catch (error) {
        console.error(`Error ${add ? "adding" : "deleting"} notification:`, error);
    }
}

// Función para generar mensajes
function generateClientMessage(clientName) {
    return {
        title: `Pending Follow-Up`,
        message: `${truncateText(clientName)} awaits follow-up.`,
        type: `client`,
        postponed: false,
        postponed_date: ``,
    };
}
  
const addOrDeleteNotification = async (id, data, shouldAdd) => {
    if (shouldAdd) {
        addDataNotifications(id, data, false, false);
    } else {
        deleteDataNotifications(id);
    }
};

