import { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { columnsPaymentRetailConfig } from '../../services/columnsList';
// import ModalC from '../modal';
// import FormConfig from './formConfig';
import Swal from "sweetalert2";
import { addDataPaymentsRetails, getDataPaymentsRetails, deleteDataPaymentsRetails, editDataPaymentsRetails } from '../../services/paymentRetail';
import { UserAuth } from '../../context/AuthContext';
import { Input } from 'antd';
import '../../style/table.css'
import ExportPDF from "../../services/exports/exportPDF";
import ExportCSV from "../../services/exports/exportCSV";
import { transformedData } from '../../services/exports/transformedData';
import { DateRangePicker } from "rsuite";
import { Table } from 'react-bootstrap';

function TableConfig() {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState({ rows: [] });
  // const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);
  // const [edit, setEdit] = useState(null);
  // const [titleModal, setTitleModal] = useState('');
  // const [expand, setExpand] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { user } = UserAuth();
  const [amount, setAmount] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [tip, setTip] = useState("");
  const [total, setTotal] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsDate, setFilteredItemsDate] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [discount, setDiscount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (user != null) {
      getDataPaymentsRetails(setData, setPending);
    }
  }, [user]);

  useEffect(() => {
    if (data != null) {

    let newData = [];
    let amountTotal = 0;
    let discountTotal = 0;
    let taxTotal = 0;
    let tipTotal = 0;
    let totalTotal = 0;

    if(data){
      data?.rows?.forEach(element => {
        amountTotal = element?.capitalPaid ? parseFloat(amountTotal) + parseFloat(element?.capitalPaid) : parseFloat(amountTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        taxTotal = element?.taxAmountPaid ? parseFloat(taxTotal) + parseFloat(element?.taxAmountPaid) : parseFloat(taxTotal);
        tipTotal = element?.tipAmountPaid ? parseFloat(tipTotal) + parseFloat(element?.tipAmountPaid) : parseFloat(tipTotal);
        totalTotal = element?.newAmountPaid ? parseFloat(totalTotal) + parseFloat(element?.newAmountPaid) : parseFloat(totalTotal);
       });
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      tip: tipTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];

    setTableList(newData);
    setAmount(parseFloat(amountTotal.toFixed(2)));
    setDiscount(parseFloat(discountTotal.toFixed(2)));
    setTaxAmount(parseFloat(taxTotal.toFixed(2)));
    setTip(parseFloat(tipTotal.toFixed(2)));
    setTotal(parseFloat(totalTotal.toFixed(2)));
    changeFilter("");

    }
  }, [data]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  // const action = (data) => {
  //   handleClearRows();

  //   if (edit) {
  //     editDataPaymentsRetails(data, true);
  //   } else {
  //     addDataPaymentsRetails(data, true);
  //   }

  //   setModal(false);
  //   setTimeout(() => {
  //     getDataPaymentsRetails(setData);
  //   }, 500);
  // }

  // const handleClearRows = () => {
  //   setToggleClearRows(!toggledClearRows);
  //   setSelects([]);
  // }

  // const editObj = (id) => {
  //   let aux = null;
  //   filteredItems.forEach(element => {
  //     if (element.id === id) {
  //       aux = element;
  //     }
  //   });
  //   setModal(true);
  //   setEdit(aux);
  //   setTitleModal('Edit payment');
  // }

  // const deleteObj = (id) => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#173d38',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       let aux = null;
  //       filteredItems.forEach(element => {
  //         if (element.id === id) {
  //           aux = element;
  //         }
  //       });
  //       deleteDataPaymentsRetails(aux);
  //       Swal.fire({
  //         title: 'Deleted!',
  //         text: 'Has been removed successfully.',
  //         icon: 'success',
  //         confirmButtonColor: '#173d38',
  //       })
  //       setTimeout(() => {
  //         setModal(false);
  //         getDataPaymentsRetails(setData);
  //         handleClearRows();
  //       }, 1000)
  //     }
  //   })
  // }

  const changeFilter = (e) => { 
    let newFilter = [];
    if(data?.rows?.length > 0){
      newFilter = data?.rows.filter(
      item => item?.retail?.[0]?.label && item?.retail?.[0]?.label.toLowerCase().includes(e.toLowerCase()) 
      || item?.paymentType?.label && item?.paymentType?.label.toLowerCase().includes(e.toLowerCase())
      || item?.client?.label && item?.client?.label.toLowerCase().includes(e.toLowerCase())
      || item?.newClientData && item?.newClientData.toLowerCase().includes(e.toLowerCase())
      || item?.pickupDate && item?.pickupDate.toLowerCase().includes(e.toLowerCase()) 
      || item?.created_date && item?.created_date.toLowerCase().includes(e.toLowerCase())
    );

    setFilteredItemsDate([]);
    setFilteredItems(newFilter);
  }
  setFilterText(e);

  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    let newData = [];
    let amountTotal = 0;
    let discountTotal = 0;
    let taxTotal = 0;
    let tipTotal = 0;
    let totalTotal = 0;

    if(filteredItems){
      filteredItems.forEach(element => {
        amountTotal = element?.capitalPaid ? parseFloat(amountTotal) + parseFloat(element?.capitalPaid) : parseFloat(amountTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        taxTotal = element?.taxAmountPaid ? parseFloat(taxTotal) + parseFloat(element?.taxAmountPaid) : parseFloat(taxTotal);
        tipTotal = element?.tipAmountPaid ? parseFloat(tipTotal) + parseFloat(element?.tipAmountPaid) : parseFloat(tipTotal);
        totalTotal = element?.newAmountPaid ? parseFloat(totalTotal) + parseFloat(element?.newAmountPaid) : parseFloat(totalTotal);
       });
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      tip: tipTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];
    
    setTableList(newData);
    setAmount(amountTotal.toFixed(2));
    setDiscount(discountTotal.toFixed(2));
    setTaxAmount(taxTotal.toFixed(2));
    setTip(tipTotal.toFixed(2));
    setTotal(totalTotal.toFixed(2));

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => changeFilter(e.target.value)} filtertext={filterText} />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const selectDate = (e) => {
    let aux = [];
    let newData = [];

    let date1 = getDate(new Date(e[0]));
    let date2 = getDate(new Date(e[1]));
    let amountTotal = 0;
    let discountTotal = 0;
    let taxTotal = 0;
    let tipTotal = 0;
    let totalTotal = 0;

    filteredItems.forEach(element => {
      if(element?.created_date >= date1 && element?.created_date <= date2){
        aux.push(element);
        amountTotal = element?.capitalPaid ? parseFloat(amountTotal) + parseFloat(element?.capitalPaid) : parseFloat(amountTotal);
        discountTotal = element?.discountAmount ? parseFloat(discountTotal) + parseFloat(element?.discountAmount) : parseFloat(discountTotal);
        taxTotal = element?.taxAmountPaid ? parseFloat(taxTotal) + parseFloat(element?.taxAmountPaid) : parseFloat(taxTotal);
        tipTotal = element?.tipAmountPaid ? parseFloat(tipTotal) + parseFloat(element?.tipAmountPaid) : parseFloat(tipTotal);
        totalTotal = element?.newAmountPaid ? parseFloat(totalTotal) + parseFloat(element?.newAmountPaid) : parseFloat(totalTotal);
      }
    });

    if(aux?.length === 0){
      aux = [{
        service:"-",
        paymentType:{value:"-",label:"-"},
        cardType:{value:"-",label:"-"},
        taxes:{value:"0",label:""},
        taxAmountPaid: 0,
        capitalPaid: 0,
        newAmountPaid: 0,
        created_date:"-",
      }];
    }

    newData = [{
      amount: amountTotal.toFixed(2),
      discount: discountTotal.toFixed(2),
      tax: taxTotal.toFixed(2),
      tip: tipTotal.toFixed(2),
      total: totalTotal.toFixed(2)
    }];
    
    setTableList(newData);
    setAmount(amountTotal.toFixed(2));
    setDiscount(discountTotal.toFixed(2));
    setTaxAmount(taxTotal.toFixed(2));
    setTip(tipTotal.toFixed(2));
    setTotal(totalTotal.toFixed(2));
    setFilteredItemsDate(aux);
    setStartDate(date1);
    setEndDate(date2);
  } 

  const getDate = (date) => {
    let day = `${(date.getDate())}`.padStart(2,'0');
    let month = `${(date.getMonth()+1)}`.padStart(2,'0');
    let year = date.getFullYear();
    return (`${year}-${month}-${day}`)
  }

  return (
    <div className="content">
      <div className='row'>
          <div className="col-md-6 text-center">
            <h6>Range Date</h6>
            <DateRangePicker 
              placeholder="Today"
              onChange={selectDate}
            />
          </div>
          <div className="d-flex justify-content-between flex-row-reverse col-md-6">
            <div className="gap-2 m-3">
                <ExportPDF data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsPaymentRetailConfig())} headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsPaymentRetailConfig(), true)} totals={[amount, taxAmount, total, tip, discount ]} agents={false} retails={true} filename={"Daily retail report"}></ExportPDF>
                <ExportCSV data={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsPaymentRetailConfig())} headers={transformedData(filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems, columnsPaymentRetailConfig(), true)} totals={[amount, taxAmount, total, tip, discount ]} agents={false} retails={true} startDate={startDate} endDate={endDate} filename={"Daily retail report"}></ExportCSV>
              </div>
          </div>
      </div>
      <div className="m-3">
        <DataTable
          columns={columnsPaymentRetailConfig()}
          data={filteredItemsDate?.length > 0 ? filteredItemsDate : filteredItems}
          // data={filteredItems}
          selectableRows
          onSelectedRowsChange={handleChange}
          pagination
          clearSelectedRows={toggledClearRows}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          progressPending={pending}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
        {/* <ModalC
          show={modal}
          onHide={() => setModal(false)}>
          <FormConfig title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModal}></FormConfig>
        </ModalC> */}

        { amount !== "0.00" && amount !== 0  ? (

          <Table style={{ maxWidth: "700px", marginLeft: "auto" }} striped bordered hover>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Discount</th>
              <th>Tax</th>
              <th>Tip</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {tableList.map((item, index) => (
              <tr key={index}>
                <td>$ {item.amount}</td>
                <td>$ {item.discount}</td>
                <td>$ {item.tax}</td>
                <td>$ {item.tip}</td>
                <td>$ {item.total}</td>
              </tr>
            ))}
          </tbody>
          </Table>


          // <div style={{textAlign:"end"}}>
          //   <div className='col-md-6' style={{marginLeft:"auto"}}>
          //     <div style={{display:"inline"}}>
          //       <h4>Amount</h4> <h5>$ {amount}</h5>
          //     </div>
          //     <h4>Tax</h4> <h5>$ {taxAmount}</h5>
          //     <h4>Total</h4> <h5>$ {total}</h5>

          //   </div>
          // </div>
        ) : ("")} 
      </div>
    </div>
  );
}

export default TableConfig;
