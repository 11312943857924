import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import Swal from "sweetalert2";
import '../../../style/formClient.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getDataRequestType } from '../../../services/shuttleSettings/shuttle';
// import { getDataLeadStatus } from '../../../services/dynamicList/leadStatus';
import { getDataPlaces } from '../../../services/shuttleSettings/places';
import { getDataAgents } from '../../../services/dynamicList/agents';
import { getDataSearchedOptions } from '../../../services/shuttleSettings/searchedOptions';
import { getDataTypeOfTaxes } from '../../../services/dynamicList/typesOfTaxes';
import { getDataExtraCost } from '../../../services/shuttleSettings/extraCost';
import { getDataDiscounts } from '../../../services/dynamicList/discounts';
import { getDataVehicleType } from '../../../services/shuttleSettings/vehicle';
import { getDataVehicleOccupation } from '../../../services/shuttleSettings/vehicleOccupation';
// import { getData } from "../../../services/crm/clients";
import { FaInfoCircle } from "react-icons/fa";
import { paymentTypes } from '../../../services/Tools';
import { useNavigate } from 'react-router-dom';
import { getInvoiceByReservation } from '../../../services/invoice';
import { BiAddToQueue } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { getDataItineraryById, getDataContractById } from '../../../services/localStorage/storage';
import { addData, getData/*, deleteData, editData*/ } from "../../../services/crm/clients";
import { sendVerificationEmail } from '../../../services/Services';
import { sendPrivateCharterEmail } from '../../../services/Services';
import { getDataOneUser } from '../../../services/auth/users';
import { UserAuth } from '../../../context/AuthContext';
import { roleVisit, YXC, YXCShuttle, YYCLateDeparture, YYC, YYCShuttleRCR, privateCharter } from '../../../services/Tools';
import FormClient from '../../clients/formClient';
import { addHours } from 'date-fns';
import ModalC from '../../modal';
import Loading from '../../../components/loading';
import { GrDocumentPdf } from "react-icons/gr";

function FormConfig({ title, action, lastData, setModal, clients, setDuplicate = null }) {

    const [id, setId] = useState("");
    const [idClient, setIdClient] = useState("");
    const [client, setClient] = useState({ value: "", label: "" });
    const [clientName, setClientName] = useState("");
    const [client_name, setClient_Name] = useState("");
    const [driverName, setDriverName] = useState("");
    const [shuttle, setShuttle] = useState("");
    const [vehicleType, setVehicleType] = useState([]);
    const [agents, setAgents] = useState([]);
    const [searchedOptions, setSearchedOptions] = useState([]);
    const [agentCommission, setAgentCommission] = useState([]);
    const [bookingType, setBookingType] = useState("Directly");
    const [searched, setSearched] = useState(false);
    // const [tax, setTax] = useState("");
    const [note, setNote] = useState("");
    // const [dataInvoice, setDataInvoice] = useState(null);
    const [modalClient, setModalClient] = useState(false);
    const [notificationAfter, setNotificationAfter] = useState(false);
    const [titleModalClient, setTitleModalClient] = useState('');
    const [invoicedVia, setInvoicedVia] = useState("");

    const [shuttleListAux, setShuttleListAux] = useState([]);
    const [shuttleList, setShuttleList] = useState([{ id: '', value: '', label: '', plate: ''}]);
    const [vehicleOccupation, setVehicleOccupation] = useState([{ id: '', value: '', label: '', plate: '', endDate: '', endTime: '', startDate: '', startTime: ''}]);

    //Almacena una lista de las fechas y los vehiculos ocupados
    const [vehicleOccupationListAux, setVehicleOccupationListAux] = useState([]);
    const [vehicleOccupationList, setVehicleOccupationList] = useState([{ value: '', label: '' }]);

    const [vehicleListAux, setVehicleListAux] = useState([]);
    const [vehicleList, setVehicleList] = useState([{ value: '', label: '' }]);

    // const [leadStatus, setLeadStatus] = useState("");

    // const [leadStatusListAux, setLeadStatusListAux] = useState([]);
    // const [leadStatusList, setLeadStatusList] = useState([{ value: '', label: '' }]);

    const [pickUp, setPickUp] = useState("");
    const [pickUpAddress, setPickUpAddress] = useState("");

    const [dropOff, setDropOff] = useState("");
    const [dropOffAddress, setDropOffAddress] = useState("");

    const [placeListAux, setPlaceListAux] = useState([]);
    const [placeList, setPlaceList] = useState([{ value: '', label: '' }]);

    const [clientListAux, setClientListAux] = useState([]);
    const [clientList, setClientList] = useState([{ value: '', label: '', email: '', phone: '', organization: '' }]);

    const [agentsListAux, setAgentsListAux] = useState([]);
    const [agentsList, setAgentsList] = useState([{ value: '', label: '' }]);   

    //Usados para la lista de searched
    const [searchedListAux, setSearchedListAux] = useState([]);
    const [searchedList, setSearchedList] = useState([{ value: '', label: '' }]); 
    
    const [taxListAux, setTaxListAux] = useState([]);
    const [taxList, setTaxList] = useState([{ value: '', label: '' }]);

    const [extraCostListAux, setExtraCostListAux] = useState([]);
    const [extraCostList, setExtraCostList] = useState([{ value: '', label: '' }]);

    const [paid, setPaid] = useState(false);
    const [showInvoice, setShowInvoice] = useState(true);
    const [nop, setNop] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [amount, setAmount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [extraAmount, setExtraAmount] = useState(0);
    const [total, setTotal] = useState(0);
    const [balance, setBalance] = useState(0);
    // const [paymentLink, setPaymentLink] = useState("");
    const [historical, setHistorical] = useState([]);
    const navigate = useNavigate();

    const [taxes, setTaxes] = useState({ value: "", label: "" });
    // const [extraCost, setExtraCost] = useState([{value: "", label: "", quantity: 1}]);
    const [extraCost, setExtraCost] = useState([]);

    const [discount, setDiscount] = useState({ value: "", label: "" });
    const [discountAmount, setDiscountAmount] = useState("");
    const [discountListAux, setDiscountListAux] = useState([]);
    const [discountList, setDiscountList] = useState([{ value: '', label: '' }]);
    const [refund, setRefund] = useState(false);
    const [refundAmount, setRefundAmount] = useState("");
    const [tipAmount, setTipAmount] = useState(0);
    const [tipAmountPaid, setTipAmountPaid] = useState(0);

    const [newClient, setNewClient] = useState(false);
    const [newClientData, setNewClientData] = useState("");
    const [quantity, setQuantity] = useState(1);

    const [fuelSurcharge, setFuelSurcharge] = useState(true);
    const [fuelSurchargeAmount, setFuelSurchargeAmount] = useState(0);
    const [fuelSurchargePercentage, setFuelSurchargePercentage] = useState(9);

    const [cardSurcharge, setCardSurcharge] = useState(false);
    const [cardSurchargeAmount, setCardSurchargeAmount] = useState(0);
    const [cardSurchargePercentage, setCardSurchargePercentage] = useState(2.4);

    const [GST, setGST] = useState({ value: '5', label: 'GST' });

    const { user, logOut } = UserAuth();
    const [userInfo, setUserInfo ] = useState('');
    const [sendEmail, setSendEmail] = useState(true);
    const [sendEmailPending, setSendEmailPending] = useState(false);
    const [urlItinerary, setUrlItinerary] = useState("");
    //const [urlContract, setUrlContract] = useState("");

    const [auxItinerary, setAuxItinerary] = useState("");
    //const [auxContract, setAuxContract] = useState("");

    //Se usa para obtener la informacion de la base de datos
    useEffect(() => {
        if (user != null) {
        const fetchData = async () => {
        try {
            await Promise.all([
                getDataRequestType(setVehicleListAux),
                getDataVehicleType(setShuttleListAux),
                getDataVehicleOccupation(setVehicleOccupationListAux),
                // getDataLeadStatus(setLeadStatusListAux);
                getDataPlaces(setPlaceListAux),
                getDataAgents(setAgentsListAux),
                getDataSearchedOptions(setSearchedListAux),
                getDataTypeOfTaxes(setTaxListAux),
                getDataExtraCost(setExtraCostListAux),
                getDataDiscounts(setDiscountListAux),
            ]);
            
            if(clients?.rows?.length > 0){
                setClientListAux(clients);
            }else{
                await getData(setClientListAux);
            }

        } catch (error) {
            // Muestra errores si es necesario
            console.log("Error to get the information");
          }
        };
      
        // Llama a la función asíncrona inmediatamente
        fetchData();

        const getUserData = async () => {
            const userDocSnap = await getDataOneUser(user.uid);
            if(userDocSnap?.length > 0){
            // if(userDocSnap[0?.role !== roleVisit]){
                setUserInfo(userDocSnap[0]);
            // }
            // else{
            //   localStorage.setItem('errorMessage', 'We are sorry but you do not have access to these system functions.');
            //   window.location.href = '/';
            // }
            }
        };
    
        getUserData();
        
        }

    }, []);

    //Use by shuttle List
    useEffect(() => {
        if (shuttleListAux?.rows?.length > 0) {
            const aux = shuttleListAux.rows.map(element => ({
                id: element.id,
                value: element.value,
                label: element.label + (element.plate ? " (" + element.plate + ")" : ""),
                plate: element.plate || "",
                occupation: "",
            }));
        
            if (lastData?.vehicleType?.length > 0) {
                const uniqueValues = new Set(lastData.vehicleType.map(item => item.value));
                return setShuttleList(aux.filter(element => !uniqueValues.has(element.value)));
            }
        
            return setShuttleList(aux);
        }
    }, [shuttleListAux]);

     //Use by vehicle List
     useEffect(() => {
        if (vehicleListAux?.rows?.length > 0) {
            var aux = [];
            vehicleListAux?.rows?.forEach(element => {
                aux.push({ value: element?.value, label: element?.label, color: element?.color ? element?.color : "#EEEEEE", fixedRate: element?.fixedRate, rate: element?.rate });
            });
            setVehicleList(aux);
        }
    }, [vehicleListAux]);

    //Use by vehicle occupation List
    useEffect(() => {
        if (vehicleOccupationListAux?.rows?.length > 0) {
            var aux = [];
            vehicleOccupationListAux?.rows?.forEach(element => {
                // aux.push({ value: element?.value, label: element?.label, fixedRate: element?.fixedRate, rate: element?.rate });
                aux.push({ id: element?.id, value: element?.value, label: element?.label, plate: element?.plate ? element?.plate : ""});
            });
            setVehicleOccupationList(aux);
        }
    }, [vehicleOccupationListAux]);

    //Use by lead status
    // useEffect(() => {
    //     if (leadStatusListAux?.rows?.length > 0) {
    //         var aux = [];
    //         leadStatusListAux?.rows?.forEach(element => {
    //             aux.push({ value: element.value, label: element.label });
    //         });
    //         setLeadStatusList(aux);
    //     }
    // }, [leadStatusListAux]);

    //Use by places
    useEffect(() => {
        if (placeListAux?.rows?.length > 0) {
            var aux = [];
            placeListAux?.rows?.forEach(element => {
                aux.push({ value: element?.color, label: element?.place });
            });
            setPlaceList(aux);
        }
    }, [placeListAux]);

     //Use by client List
     useEffect(() => {
        if (clientListAux?.rows?.length > 0) {
            var aux = [];
            clientListAux?.rows?.forEach(element => {
                let orga = element?.organisation;
                if(orga){
                    aux.push({ value: element?.id, label: element?.client, email: element?.email, phone: element?.phone, organization: orga });
                }else{
                    aux.push({ value: element?.id, label: element?.client, email: element?.email, phone: element?.phone, organization : "-" });
                }
            });
            setClientList(aux);
        }
    }, [clientListAux]);

    //Use by agents
    useEffect(() => {
        if (agentsListAux?.rows?.length > 0) {
            var aux = [];
            agentsListAux?.rows?.forEach(element => {
                aux.push({ value: element?.id, label: element?.name, commission: element?.commission });
            });
            setAgentsList(aux);
        }
    }, [agentsListAux]);

    //Use by searched options
    useEffect(() => {
        if (searchedListAux?.rows?.length > 0) {
            var aux = [];
            searchedListAux?.rows?.forEach(element => {
                aux.push({ value: element?.id, label: element?.label,  value: element?.value});
            });
            setSearchedList(aux);
        }
    }, [searchedListAux]);

    //Use by taxes
    useEffect(() => {
        if (taxListAux?.rows?.length > 0) {
            var aux = [];
            taxListAux?.rows?.forEach(element => {
                if(element?.type === "Fuel Surcharge"){
                    setFuelSurchargePercentage(element?.tax);
                }else{
                    aux.push({ value: element?.tax, label: element?.type });
                }

                if(element?.type === "GST"){
                    setGST({ value: element?.tax, label: element?.type } );
                }
            });
            setTaxList(aux);
        }
    }, [taxListAux]);

    //Use by extra cost
    useEffect(() => {
        if (extraCostListAux?.rows?.length > 0) {
            var aux = [];
            extraCostListAux?.rows?.forEach(element => {
                aux.push({ value: element?.cost, label: element?.type, quantity: 1 });
            });
            setExtraCostList(aux);
        }
    }, [extraCostListAux]);

    //Use by discount
    useEffect(() => {
        if (discountListAux?.rows?.length > 0) {
            var aux = [];
            discountListAux?.rows?.forEach(element => {                
                
                aux.push({ value: element?.discount, label: (element?.type + ( " " + element?.discount +"%")) });

            });
            setDiscountList(aux);
        }
    }, [discountListAux]);

    //Llena los campos de informacion si es un edit
    useEffect(() => {
        const fetchData = async () => {
            if (lastData) {
                setId(lastData?.id);
                setIdClient(lastData?.idClient);
                setNewClient(lastData?.newClient);
                setNewClientData(lastData?.newClientData);
                setShuttle({ value: lastData?.shuttle?.value, label: lastData?.shuttle?.label, color: lastData?.shuttle?.color ? lastData?.shuttle?.color : "#EEEEEE", 
                            fixedRate: lastData?.shuttle?.fixedRate ? lastData?.shuttle?.fixedRate : false, rate: lastData?.shuttle?.rate });
                // setLeadStatus({ value: lastData?.leadStatus?.value, label: lastData?.leadStatus?.label });
                setPickUp({ value: lastData?.pickUp?.value, label: lastData?.pickUp?.label });
                setPickUpAddress(lastData?.pickUpAddress);
                setDropOff({ value: lastData?.dropOff?.value, label: lastData?.dropOff?.label });
                setDropOffAddress(lastData?.dropOffAddress);
                setClient( !newClient ? ({ value: lastData?.client?.value, label: lastData?.client?.label }) : ({ value: "", label: "" }));
                setClientName(lastData?.client?.label ? lastData?.client?.label : lastData?.newClientData);
                setClient_Name(lastData?.client_name ? lastData?.client_name : (lastData?.client?.label ? lastData?.client?.label : lastData?.newClientData));
                setDriverName(lastData?.driverName ? lastData?.driverName : "");
                setInvoicedVia(lastData?.invoicedVia ? ({ value: lastData?.invoicedVia?.value, label: lastData?.invoicedVia?.label }) : ({ value: "", label: "" }));
                setBookingType(lastData?.bookingType);
                setRefund(lastData?.refund ? lastData?.refund : false);
                setRefundAmount(lastData?.refundAmount ? lastData?.refundAmount : 0);
                setTipAmount(lastData?.tipAmount ? lastData?.tipAmount : 0);
                setTipAmountPaid(lastData?.tipAmountPaid ? lastData?.tipAmountPaid : 0);
                setSearched(lastData?.searched ? lastData?.searched : false);
                setNotificationAfter(lastData?.notificationAfter ? lastData?.notificationAfter : false);

                //Llena el campo del AGENTS si este tiene informacion guardada
                if(lastData?.agents?.value){
                    setAgents({ value: lastData?.agents?.value, label: lastData?.agents?.label, commission: lastData?.agents?.commission });
                }

                //Llena el campo del AGENTCOMMISION si este tiene informacion guardada
                if(lastData?.agentCommission){
                    setAgentCommission(lastData?.agentCommission);
                }

                //Llena el campo del SEARCHED si este tiene informacion guardada
                if(lastData?.searched){
                    setSearchedOptions({ value: lastData?.searchedOptions?.value, label: lastData?.searchedOptions?.label});
                }
                
                // setTax({ value: lastData?.tax?.value, label: lastData?.tax?.label });
                setPaid(lastData?.paid);
                setShowInvoice(lastData?.showInvoice);
                setNote(lastData?.note);
                setNop(lastData?.nop);
                setStartDate(lastData?.startDate);
                setEndDate(lastData?.endDate);
                setStartTime(lastData?.startTime);
                setEndTime(lastData?.endTime);
                setAmount(lastData?.amount ? lastData?.amount : 0);
                setAmountPaid(lastData?.amountPaid ? lastData?.amountPaid : 0);
                setTaxAmount(lastData?.taxAmount ? lastData?.taxAmount : 0);
                setExtraAmount(lastData?.extraAmount ? lastData?.extraAmount : 0);
                setTotal(lastData?.total ? lastData?.total : 0);
                setBalance(lastData?.balance ? lastData?.balance : 0);
                // setPaymentLink(lastData?.paymentLink);
                setFuelSurcharge(lastData?.fuelSurcharge ? lastData?.fuelSurcharge : false);
                setFuelSurchargeAmount(lastData?.fuelSurchargeAmount ? lastData?.fuelSurchargeAmount : 0);
                setFuelSurchargePercentage(lastData?.fuelSurchargePercentage ? lastData?.fuelSurchargePercentage : 9);
                setCardSurcharge(lastData?.cardSurcharge ? lastData?.cardSurcharge : false);
                setCardSurchargeAmount(lastData?.cardSurchargeAmount ? lastData?.cardSurchargeAmount : 0);
                setCardSurchargePercentage(lastData?.cardSurchargePercentage ? lastData?.cardSurchargePercentage : 2.4); 
                setTaxes({ value: lastData?.taxes?.value, label: lastData?.taxes?.label });
                setDiscount({ value: lastData?.discount?.value, label: lastData?.discount?.label });
                setDiscountAmount(lastData?.discountAmount);
                setVehicleOccupation(lastData?.vehicleOccupation ? lastData?.vehicleOccupation : []);
                
                if (Array.isArray(extraCost)) {
                    let data = [...extraCost];
                    //if(lastData?.extraCost?.length > 0){
                    for (let i = 0; lastData?.extraCost?.length > i; i++) {
                        data[i] = lastData?.extraCost[i];
                    }
                    setExtraCost(data);
                    //}
                } else {
                    let data = [];
                    data[0] = lastData?.extraCost[0];
                    setExtraCost(data);
                }

                if (Array.isArray(vehicleType)) {
                    let data = [...vehicleType];
                    for (let i = 0; lastData?.vehicleType?.length > i; i++) {
                        data[i] = lastData?.vehicleType[i];
                    }
                    setVehicleType(data);
                } else {
                    let data = [];
                    data[0] = lastData?.vehicleType[0];
                    setVehicleType(data);
                }

                try {
                    const auxI = await getDataItineraryById(lastData?.id);
                    //const auxC = await getDataContractById(data?.id);

                    setUrlItinerary(auxI?.[0]?.file_url);
                    setAuxItinerary(auxI?.[0]);

                    //setUrlContract(auxC?.[0]?.file_url);
                    //setAuxContract(auxC?.[0]);

                } catch (error) {
                console.error("Error fetching itinerary or contract data");
                // Handle errors gracefully (e.g., display error message to user)
                }
                
                // getInvoiceByReservation(lastData?.id, setDataInvoice);
            }
        };
    
        fetchData();

    }, [lastData]);

    // useEffect(() => {
    //     if (vehicleOccupationListAux?.rows?.length > 0 && lastData) {
    //         changeVehicleAvailability(lastData.startDate, lastData.endDate, lastData.startTime, lastData.endTime);
    //     }
    // }, [vehicleOccupationListAux, lastData]);

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    const checkData = (data) => {

        // let startDate1 = Date.parse(data.startDate);
        //English - Canada	en	en-ca             Spanish - Costa Rica	es	es-cr
        // let startDate1 = new Date(data?.startDate + "T" + data?.startTime + ":00.000Z").toLocaleDateString('en-ca');
        // let endDate2 = new Date(data?.endDate + "T" + data?.endTime + ":00.000Z").toLocaleDateString('en-ca'); //new Date(data.endDate);

        let startTime1 = data?.startTime;
        let endTime2 = data?.endTime;

        // let today = Date.now();
        // let todayDate = new Date(today).toLocaleDateString('en-ca');
        // let now = Date.parse(today);

        // let currentDateTime = new Date(today).getTime();

        const newStartTime = new Date();
        const [hoursStart, minutesStart] = startTime1.split(":");
        newStartTime.setHours(hoursStart, minutesStart);
        const startTimeMilisecond = newStartTime.getTime();

        const newEndTime = new Date();
        const [hoursEnd, minutesEnd] = endTime2.split(":");
        newEndTime.setHours(hoursEnd, minutesEnd);
        const endTimeMilisecond = newEndTime.getTime();

        let success = true;
        let extraNull = false;
        let vehicleNull = false;

        clientListAux?.rows?.forEach(element => {
            if(element?.client === data?.client?.label){
                data['client'] = { value: element?.id, label: element?.client };
                data['idClient'] = element?.id;
                setClient({ value: element?.id, label: element?.client });
                setClientName(element?.client);
                setClient_Name(element?.client);
            }
        });
        
        if (amount !== '' && shuttle !== '' && nop !== '' && startDate !== '' && endDate !== '' && startTime !== '' && endTime !== '' && total !== '' && taxes?.value !== "") {

            extraCost.forEach(element => {
                if(element === ''){
                    success = false;
                    extraNull = true;
                }
            });

            if(vehicleType?.length > 0){
                vehicleType.forEach(element => {
                    if(element === ''){
                        success = false;
                        vehicleNull = true;
                    }else{

                        // if(vehicleOccupationListAux?.rows?.length > 0){
                        //     if(lastData !== null){
                        //         if(checkIsVehicleOccupied(element, vehicleOccupationListAux?.rows, data?.startDate, data?.startTime, data?.endDate, data?.endTime, true)){
                        //             Swal.fire({
                        //                 icon: "error",
                        //                 title: "Error!",
                        //                 text: "The vehicle with license plate "+element?.plate+" isn't available for the chosen time.",
                        //                 confirmButtonColor: '#173d38',
                        //                 confirmButtonText: "OK",
                        //             });
                        //             success = false;
                        //         }
                        //     }else{
                        //         if(checkIsVehicleOccupied(element, vehicleOccupationListAux?.rows, data?.startDate, data?.startTime, data?.endDate, data?.endTime, false)){
                        //             Swal.fire({
                        //                 icon: "error",
                        //                 title: "Error!",
                        //                 text: "The vehicle with license plate "+element?.plate+" isn't available for the chosen time.",
                        //                 confirmButtonColor: '#173d38',
                        //                 confirmButtonText: "OK",
                        //             });
                        //             success = false;
                        //         }
                        //     }
                        // }
                    }
                });

            }

            if(newClient){
                if(newClientData === ""){
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "It is necessary to add unregistered client name.",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                    success = false;
                }
            }else{
                if(!client || data['client'] === ""){
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "It is necessary to add client name.",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                    success = false;
                }
            }

            if(taxes?.value === undefined ){ 
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to add a tax.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
                success = false;
            }

            if(extraNull){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to add an extra cost.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
            }

            if(vehicleNull){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to add a vehicle type.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
            }

            if(data?.startDate > data?.endDate){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "The start date cannot be later than the end date",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
                success = false;
            }   

            if(startTimeMilisecond > endTimeMilisecond && success){
                if(data?.startDate >= data?.endDate){
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "The start time cannot be later than the end time",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                    success = false;
                }
            }

            if(bookingType === "Agent" && agents.length === 0){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It's necessary to choose the agent.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                }); 
                success = false;
            }

            if(searched === true && searchedOptions.length === 0){
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It's necessary to choose a searched option.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                }); 
                success = false;
            }

            if(!data?.newClient){
                if(data?.client?.value === ""){
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "Client information has not been added.",
                        confirmButtonColor: '#173d38',
                        confirmButtonText: "OK",
                    });
                    success = false;
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to register the client (CRM Information).",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
                success = false;
            }

            if(pickUp?.value ===  undefined ){ 
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to add a Pick up.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
                success = false;
            }

            if(dropOff?.value ===  undefined ){ 
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "It is necessary to add a Drop off.",
                    confirmButtonColor: '#173d38',
                    confirmButtonText: "OK",
                });
                success = false;
            }


            if(success){
                if(balance < 0){ 
                    Swal.fire({
                        title: 'Negative Balance',
                        text: 'Are you sure about this action?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#173d38',
                        confirmButtonText: 'Confirm',
                        cancelButtonColor: '#dc3545',
                        cancelButtonText: 'Cancel',
                      }).then((result) => {
                        if (result.isConfirmed) {
                            // data['refund'] = (parseFloat(data?.balance) + parseFloat(data?.amountPaid)) == 0 ? true : false;
                            // data['refundAmount'] = parseFloat(balance);
                            data['paid'] = false;
                            action(data, lastData);
                        }
                      });
                }else{
                    if(balance === 0){
                        data['paid'] = true;
                    }else{
                        data['paid'] = false;
                    }
                    action(data, lastData);
                }
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "All fields with (*) are required",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
              });
        }
    }

    const onChangeShuttle = (pStartTime, pShuttle) => {
        setShuttle(pShuttle);
        if (pShuttle.value === YYC || pShuttle.value === YXC ){
            automaticTotal(pShuttle,nop,taxes,extraCost,fuelSurcharge,discount,tipAmount,cardSurcharge);
            setEndTime(pStartTime);
            setEndDate(startDate);
        }
        setSendEmail(false);
    }

    const sendBookingConfirmation = () => {
        if(lastData?.shuttle?.value == YYC || lastData?.shuttle?.value == YYCShuttleRCR || lastData?.shuttle?.value == YYCLateDeparture){
            setSendEmailPending(true);
            sendVerificationEmail(lastData, clientList, setSendEmailPending, "YYC");
            setSendEmail(false);
        } else if(lastData?.shuttle?.value == YXC || lastData?.shuttle?.value == YXCShuttle){
            setSendEmailPending(true);
            sendVerificationEmail(lastData, clientList, setSendEmailPending, "YXC");
            setSendEmail(false);
        }
    }

    const sendPrivateCharterConfirmation = () => {
        if(lastData?.shuttle?.value == privateCharter){
            setSendEmailPending(true);
            sendPrivateCharterEmail(lastData, clientList, setSendEmailPending, "Private Charter");
            setSendEmail(false);
        }
    }

    const showClient = () => {
        Swal.fire({
            title: '<strong>Client information</strong>',
            icon: 'info',
            html: `                              
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th><small>Name</small></th>
                <th><small>Organization</small></th>
                <th><small>Email</small></th>
                <th><small>Phone</small></th>
              </tr>
            </thead>
            <tbody>
                ${getClient()}
            </tbody>
          </table>`,
            showCloseButton: true,
            confirmButtonColor: "#173d38",
            width: 700,
        })
        // <th><small>Action</small></th>
    }

    const getClient = () => {
        var tbody = "";

        if (clientList) {
            clientList.forEach(element => {
                if(element?.value === client?.value){
                    let orga = element?.organization;
                    let pho = element?.phone;
                    // let id = element?.value;
                    tbody = tbody + `<tr><td><small>${element?.label}</small></td><td style="max-width:100px"><small>${orga ? (orga) : ("-")}</small></td><td><small>${element?.email}</small></td><td><small>${ pho ? (pho) : ("-")}</small></td> </tr>`
                    // <td><small><a title="Go to client transports" href="/clients/bookings/${id}">Client transports</a></small></td>
                }
            });
        } else {
            tbody = "<tr><td colspan='6'>Without results</td></tr>";
        }
        return tbody;
    }

    // const leadStatusHistorical = () => {
    //     Swal.fire({
    //         title: '<strong>Historical</strong>',
    //         icon: 'info',
    //         html: `                              
    //       <table class="table table-sm table-striped">
    //         <thead>
    //           <tr>
    //             <th scope="col">New</th>
    //             <th scope="col">Updated</th>
    //             <th scope="col">Change by</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           ${getHistorical()}
    //         </tbody>
    //       </table>`,
    //         showCloseButton: true,
    //         confirmButtonColor: "#173d38"
    //     })
    // }

    // const getHistorical = () => {
    //     var tbody = "";
    //     if (historical?.length > 0) {
    //         historical.forEach(element => {
    //             tbody = tbody + `<tr><td>${element?.newValue}</td><td>${element?.updated}</td><td>${element?.change}</td></tr>`
    //         });
    //     } else {
    //         tbody = "<tr><td colspan='4'>Without results</td></tr>";
    //     }
    //     return tbody;
    // }

    const changeClient = (e) => {
        setClient(e);
        setIdClient(e?.value);
        setClientName(e?.label);
        setClient_Name(e?.label);
        setSendEmail(false);
    }

    const changeBookingType = (e) => {

        if(e === "Directly"){
            setAgents([]);
            setBookingType("Directly");
        }else{
            setBookingType("Agent");
        }
    }

    const changeSearched = (e) => {
        if(e === false){
            setSearchedOptions([]);
            setSearched(false);
        }else{
            setSearched(true);
        }
    }

    const changeNotificationAfter = (e) => {
        if(e === false){
            setNotificationAfter(false);
        }else{
            setNotificationAfter(true);
        }
    }

    const calculateTotal = (value, tax, extra = null, fuel = fuelSurcharge, disco, tip, card = cardSurcharge) => {
        if(tax?.value !== ""){
            let amo = value ? parseFloat(value) : 0; //Monto sin impuestos ni descuento
            let cal = 0; //calculo
            let imp = 0; //impuesto 
            let impExtra = 0; //Impuesto extra para las cosas extras, sumatoria
            let tot = 0; //total
            let bal = 0; //balance
            let paid = 0; //monto pagado
            let sur = 0; // fuel surcharge
            let ext = 0; // Monto extra, sumatoria
            let dis = 0; //El descuento que agreguen
            let car = 0; //Se guarda el monto del card surcharge

            if(lastData){
                bal = lastData?.balance ? lastData?.balance : 0;
                paid = lastData?.amountPaid >= 0 ? lastData?.amountPaid : 0;
            }

            if(amo > 0){
                //Metodo para calcular el monto del descuento y sobre escribe el monto = amo
                if(disco?.value){
                    dis = parseFloat(amo) * (parseFloat(disco?.value) / 100);
                    amo = parseFloat(amo) - parseFloat(dis);
                }

                if(card){
                    car = parseFloat(amo) > 0 ? parseFloat(amo) * (parseFloat(cardSurchargePercentage) / 100) : parseFloat(value) * (parseFloat(cardSurchargePercentage) / 100);
                }

                if(fuel){
                    //Metodo para calcular el monto de los impuestos a pagar
                   
                    sur = parseFloat(amo) * (parseFloat(fuelSurchargePercentage) / 100);
                    // cal = parseFloat(amo) + parseFloat(sur) + parseFloat(car);
                    cal = parseFloat(amo) + parseFloat(sur);
                    cal = parseFloat(cal) * (parseFloat(tax?.value) / 100);
                    imp = parseFloat(imp) + parseFloat(cal);

                }else{

                    // cal = (parseFloat(amo) + parseFloat(car)) * (parseFloat(tax?.value) / 100);
                    cal = parseFloat(amo) * (parseFloat(tax?.value) / 100);
                    imp = parseFloat(imp) + parseFloat(cal);

                }
    
                tot = parseFloat(value) + parseFloat(imp) + parseFloat(sur) + parseFloat(car) - parseFloat(dis); 

            }

            //Metodo para obtener el impuesto de los extras (lista dinamica)
            if(extra?.length > 0){
                extra.forEach(element => {
                    if(element !== ''){
                        impExtra = parseFloat(impExtra) + (parseFloat(parseFloat(element?.value) * parseFloat(element.quantity)) * parseFloat(parseFloat(GST?.value) / 100));
                        ext = parseFloat(ext) + ( parseFloat(element?.value) * parseFloat(element.quantity));
                    }
                });
            }

            tot = parseFloat(tot) + parseFloat(impExtra) + parseFloat(ext); 
            bal = (parseFloat(tot) + parseFloat(tip)) - parseFloat(paid);
            // bal = parseFloat(tot) - parseFloat(paid);
            imp = parseFloat(imp) + parseFloat(impExtra);
            
            setCardSurchargeAmount(parseFloat(car.toFixed(2)));
            setFuelSurchargeAmount(parseFloat(sur.toFixed(2)));
            setTaxAmount(parseFloat(imp.toFixed(2)));
            setExtraAmount(parseFloat(ext.toFixed(2)));
            setAmount(value ? parseFloat(value) : "");
            setTotal(parseFloat(tot.toFixed(2)));
            setAmountPaid(parseFloat(paid.toFixed(2)));
            setBalance(parseFloat(bal.toFixed(2)));
            setDiscountAmount(parseFloat(dis.toFixed(2)));

        }else{
            Swal.fire({
                icon: "warning",
                title: "Error!",
                text: "Please, choose the tax rate first",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
        }
    }

    const automaticTotal = (trip, people, tax, extra = null, fuel = fuelSurcharge, disco, tip, card = cardSurcharge) => {

        // if(trip?.fixedRate){
        let value = amount; //Monto sin impuestos ni descuento
        let amo = 0; //Monto sin impuestos
        let cal = 0; //calculo
        let imp = 0; //impuesto
        let impExtra = 0; //Impuesto extra para las cosas extras, sumatoria
        let tot = 0; //total
        let bal = 0; //balance
        let paid = 0; //monto pagado
        let sur = 0; // fuel surcharge
        let ext = 0; // Monto extra, sumatoria
        let dis = 0; //El descuento que agreguen
        let car = 0; //Se guarda el monto del card surcharge

        if(lastData){
            bal = lastData?.balance ? lastData?.balance : 0;
            paid = lastData?.amountPaid >= 0 ? lastData?.amountPaid : 0;
        }

        if(trip?.fixedRate){
            if(people){
                value = parseFloat(trip?.rate) * parseFloat(people);
            }else{
                value = 0;
            }
            amo = parseFloat(value);
        }

        if(value){

            //Metodo para calcular el monto del descuento y sobre escribe el monto = amo
            if(disco?.value){
                dis = parseFloat(value) * (parseFloat(disco?.value) / 100);
                amo = parseFloat(value) - parseFloat(dis);
            }

            if(card){
                car = parseFloat(amo) > 0 ? parseFloat(amo) * (parseFloat(cardSurchargePercentage) / 100) : parseFloat(value) * (parseFloat(cardSurchargePercentage) / 100);
            }

            //Metodo para calcular el monto de los impuestos a pagar
            if(fuel){
                sur = parseFloat(amo) > 0 ? parseFloat(amo) * (parseFloat(fuelSurchargePercentage) / 100) : parseFloat(value) * (parseFloat(fuelSurchargePercentage) / 100);
                // cal = parseFloat(amo) + parseFloat(sur) + parseFloat(car);
                cal = parseFloat(amo) + parseFloat(sur);

                if(tax?.value != ""){
                    cal = parseFloat(cal) > 0 ? parseFloat(cal) * (parseFloat(tax?.value) / 100) : parseFloat(value) * (parseFloat(tax?.value) / 100);
                    imp = parseFloat(imp) + parseFloat(cal);
                }
            } else {
                // cal = (parseFloat(value) + parseFloat(car)) * (parseFloat(tax?.value) / 100);
                cal = (parseFloat(value)) * (parseFloat(tax?.value) / 100);
                imp = parseFloat(imp) + parseFloat(cal);
            }
            // tot = parseFloat(amo) + parseFloat(imp) + parseFloat(sur); 
            tot = parseFloat(value) + parseFloat(imp) + parseFloat(sur) - parseFloat(dis); 
        }

        //Metodo para obtener el impuesto de los extras (lista dinamica)
        if(extra?.length > 0){
            extra.forEach(element => {
                if(element !== ''){
                    impExtra = parseFloat(impExtra) + (parseFloat(parseFloat(element?.value) * parseFloat(element?.quantity)) * parseFloat(parseFloat(GST?.value) / 100));
                    ext = parseFloat(ext) + ( parseFloat(element?.value) * parseFloat(element.quantity));
                }
            });
        }

        tot = parseFloat(tot) + parseFloat(impExtra) + parseFloat(car) + parseFloat(ext);
        bal = (parseFloat(tot) + parseFloat(tip)) - parseFloat(paid);
        // bal = parseFloat(tot) - parseFloat(paid);
        imp = parseFloat(imp) + parseFloat(impExtra);
        
        setCardSurchargeAmount(parseFloat(car.toFixed(2)));
        setFuelSurchargeAmount(parseFloat(sur.toFixed(2)));
        setTaxAmount(parseFloat(imp.toFixed(2)));
        setExtraAmount(parseFloat(ext.toFixed(2)));
        setAmount(parseFloat(value) > 0 ? parseFloat(value.toFixed(2)) : 0);
        setTotal(parseFloat(tot.toFixed(2)));
        setAmountPaid(parseFloat(paid.toFixed(2)));
        setBalance(parseFloat(bal.toFixed(2)));
        setDiscountAmount(parseFloat(dis.toFixed(2)));
        // }
    }

    // const addInputField = (e) => {
    //     e.preventDefault();
    //     setTaxes([...taxes, ""]);
    // }

    const addInputFieldExtraCost = (e) => {
        
        if(taxes?.value !== ""){
            e.preventDefault();
            setExtraCost([...extraCost, ""]);
            setSendEmail(false);
        }else{
            Swal.fire({
                icon: "warning",
                title: "Error!",
                text: "Please, choose the tax rate first",
                confirmButtonColor: '#173d38',
                confirmButtonText: "OK",
            });
        }

    }

    // const handleInputChange = (index, e) => {
    //     let data = [...taxes];
    //     data[index] = e;
    //     setTaxes(data);
    //     if(taxes.length > 0){
    //         // calculateTotal(amount, data, extraCost);
    //         automaticTotal(shuttle,nop,data,extraCost,fuelSurcharge,discount);
    //     }
    // }

    const handleInputChangeExtraCost = (index,extra,e) => {
        let data = [...extraCost];

        data[index] = extra;

        if(data[index]){
            data[index].quantity = e ? e : quantity;
            setQuantity(quantity);
        }

        setSendEmail(false);
        setExtraCost(data);
        // calculateTotal(amount, taxes, data);
        //amount ? calculateTotal(amount,taxes, data, true, discount, tipAmount, cardSurcharge) : automaticTotal(shuttle,nop,taxes,data,fuelSurcharge,discount, tipAmount, cardSurcharge);
        automaticTotal(shuttle, nop, taxes, data, fuelSurcharge, discount, tipAmount, cardSurcharge);
    }

    // const removeFields = (index) => {
    //     let data = [...taxes];
    //     data.splice(index, 1);
    //     setTaxes(data);
    //     // calculateTotal(amount, data, extraCost);
    //     automaticTotal(shuttle,nop,data,extraCost,fuelSurcharge,discount);
    // }

    const removeFieldsExtraCost = (index) => {
        let data = [...extraCost];
        data.splice(index, 1);
        setExtraCost(data);
        setSendEmail(false);
        // calculateTotal(amount, taxes, data);
        //amount ? calculateTotal(amount,taxes, data, true, discount, tipAmount, cardSurcharge) : automaticTotal(shuttle,nop,taxes,data,fuelSurcharge,discount, tipAmount, cardSurcharge);
        automaticTotal(shuttle, nop, taxes, data, fuelSurcharge, discount, tipAmount, cardSurcharge);
    }

    const openClientModal = () => {
        setModalClient(true);
        setTitleModalClient('Add Client');
    };

    const addInputFieldVehicleType = (e) => {
        e.preventDefault();
        setVehicleType([...vehicleType, ""]);
        setSendEmail(false);
    }

    const handleInputChangeVehicleType = (index, selectedOption) => {
        const updatedVehicleType = [...vehicleType];
        const previouslySelected = updatedVehicleType[index];

        // Elimina la opción seleccionada de las opciones disponibles
        setShuttleList(prevOptions => prevOptions.filter(option => option.value !== selectedOption.value));

        // Devuelve la opción previamente seleccionada a las opciones disponibles
        if (previouslySelected) {
            setShuttleList(prevOptions => [...prevOptions, previouslySelected]);
        }

        updatedVehicleType[index] = selectedOption;
        setVehicleType(updatedVehicleType);
        setSendEmail(false);
    };

    const removeFieldsVehicleType = (index) => {

        // Obtener el valor que se va a eliminar del array vehicleType
        const removedOption = vehicleType[index];

        // Verifica que no este vacio y devuelve la opción al dropdown cuando se elimina un campo
        if(removedOption){
            setShuttleList(prevOptions => [...prevOptions, removedOption]);            
        }
        // Copia el array al vehicleType y elimina el elemento en el índice dado
        const updatedVehicleType = [...vehicleType];
        updatedVehicleType.splice(index, 1);

        // Actualiza el estado de vehicleType con el nuevo array actualizado
        setVehicleType(updatedVehicleType);
        setSendEmail(false);
    }

    const actionClient = (data) => {
        // handleClearRows();
        data['owner'] = data['owner'] ? data['owner'].value : "";
        setNewClientData(data['client']);

        addData(data, true);
        setModalClient(false);

        getData(setClientListAux);
        setClient({ value: '', label: data['client'] });
        setClientName(data['client']);
        setClient_Name(data['client']);
        
        setNewClient(false);
        setNewClientData("");

    }

    // const changeVehicleAvailability = (startDate, endDate, startTime, endTime) => {

    //     if(startDate !== "", endDate !== "", startTime !== "", endTime !== ""){

    //         // if(vehicleOccupationListAux.length < 1){

    //         //     getDataVehicleOccupation(setVehicleOccupationListAux);
    //         // }

    //         if (vehicleOccupationListAux?.rows?.length > 0 && shuttleListAux?.rows?.length > 0) {
    //             const availableVehicles = [];

    //             shuttleListAux.rows.forEach(vehicle => {
    //                 const isOccupied = isVehicleOccupied(vehicle, vehicleOccupationListAux?.rows, startDate, startTime, endDate, endTime, lastData ? true : false);

    //                 if (!isOccupied) {

    //                     // Verificar si el vehículo ya está en setShuttleList por la placa
    //                     const isAlreadyInList = vehicleType.some(available => available.value === vehicle.value);

    //                     if (!isAlreadyInList) {
    //                         availableVehicles.push({
    //                             id: vehicle.id,
    //                             value: vehicle.value,
    //                             label: vehicle?.label + (vehicle?.plate ? (" (" + vehicle?.plate + ")") : ""),
    //                             plate: vehicle.plate || '',
    //                         });
    //                     }
    //                 }

    //             });

    //             let vehicles = [];

    //             vehicleType.forEach(vehicle => {
    //                 const isAlreadyInOccupation = vehicleOccupationListAux?.rows?.find(available => available.value === vehicle.value
    //                     && available.startDate === startDate
    //                     && available.startTime === startTime);

    //                 if(isAlreadyInOccupation){
    //                     vehicles.push(isAlreadyInOccupation);
    //                 }
    //             });

    //             setVehicleOccupation(vehicles);
    //             setShuttleList(availableVehicles);

    //         } else if (shuttleListAux?.rows?.length > 0) {
    //             const aux = shuttleListAux.rows.map(element => ({
    //                 id: element.id,
    //                 value: element.value,
    //                 label: element?.label + (element?.plate ? (" (" + element?.plate + ")") : ""), 
    //                 plate: element.plate || '',
    //                 occupation: '',
    //             }));
            
    //             setShuttleList(aux);
    //         }

    //     }
        
    // }

// function isVehicleOccupied(vehicle, occupationList, startDate, startTime, endDate, endTime, edit = false) {
//     const queryStartDateTime = new Date(`${startDate} ${startTime}`);
//     const queryEndDateTime = addHours(new Date(`${endDate} ${endTime}`), 2);
//     let i = 0;

//     // return occupationList.reduce((result, occupation) => {
//     //     if (!result.isOccupied) {
//         return occupationList.some(occupation => {

//             if (!edit) {
//                 // Lógica para cuando no se está editando
//                 if (
//                     occupation?.value === vehicle?.value &&
//                     occupation?.startDate &&
//                     occupation?.startTime &&
//                     occupation?.endDate &&
//                     occupation?.endTime
//                 ) {

//                     const occupationStartDateTime = new Date(`${occupation.startDate} ${occupation.startTime}`);
//                     const occupationEndDateTime = addHours(new Date(`${occupation.endDate} ${occupation.endTime}`), 2);

//                     // Verifica si las fechas se superponen
//                     const isOverlap = !(occupationEndDateTime <= queryStartDateTime || occupationStartDateTime >= queryEndDateTime);

//                     // Si hay superposición, el vehículo está ocupado
//                     if (isOverlap) {
//                         return true;
//                         // result.isOccupied = true;
//                     }

//                     // Verifica si la nueva reserva está entre dos reservas existentes
//                     if (queryStartDateTime >= occupationEndDateTime && queryEndDateTime <= occupationStartDateTime) {
//                         return true;
//                         // result.isOccupied = true;
//                     }
//                 }
//                 return false;
//                 // return result;
//             } else {
//                 // Lógica para cuando se está editando
//                 const isAlreadyInList = lastData?.vehicleType.some(available => available.value === vehicle.value);

//                 if (!isAlreadyInList) {
//                     if (
//                         occupation?.value === vehicle?.value &&
//                         occupation?.startDate &&
//                         occupation?.startTime &&
//                         occupation?.endDate &&
//                         occupation?.endTime
//                     ){
//                         i++;
//                         const occupationStartDateTime = new Date(`${occupation.startDate} ${occupation.startTime}`);
//                         const occupationEndDateTime = addHours(new Date(`${occupation.endDate} ${occupation.endTime}`), 2);

//                         // Verifica si las fechas se superponen
//                         const isOverlap = !(occupationEndDateTime <= queryStartDateTime || occupationStartDateTime >= queryEndDateTime);

//                         // Si hay superposición, el vehículo está ocupado
//                         if (isOverlap) {
//                             return true;
//                             // result.isOccupied = true;
//                         }

//                         // Verifica si la nueva reserva está entre dos reservas existentes
//                         if (queryStartDateTime >= occupationEndDateTime && queryEndDateTime <= occupationStartDateTime) {
//                             return true;
//                             // result.isOccupied = true;
//                         }
//                     }
//                 }

//                 // if(isAlreadyInOccupation){
//                 //     result.occupation.push
//                 //     result.occupationInfo.push(matchingOccupation);

//                 // }

//                 // result.isOccupied = false;
//                 return false;
//             }
//         });

//     //     if (!result.isOccupied) {
//     //         // Verifica si la ocupación ya está en lastData?.vehicleType
//     //         const isAlreadyInOccupation = vehicleOccupationListAux?.rows?.find(available => available.value === vehicle.value 
//     //             && available.startDate === startDate
//     //             && available.startTime === startTime);

//     //         if (isAlreadyInOccupation) {
//     //             result.occupationInfo.push(occupation);
//     //         }
//     //     }
        
//     //     return result;
//     // }, { isOccupied: false, occupationInfo: [] });
// }

// function checkIsVehicleOccupied(vehicle, occupationList, startDate, startTime, endDate, endTime, edit = false) {
//     const queryStartDateTime = new Date(`${startDate} ${startTime}`);
//     const queryEndDateTime = addHours(new Date(`${endDate} ${endTime}`), 2);

//         return occupationList.some(occupation => {

//             if (!edit) {
//                 // Lógica para cuando no se está editando
//                 if (
//                     occupation?.value === vehicle?.value &&
//                     occupation?.startDate &&
//                     occupation?.startTime &&
//                     occupation?.endDate &&
//                     occupation?.endTime
//                 ) {

//                     const occupationStartDateTime = new Date(`${occupation.startDate} ${occupation.startTime}`);
//                     const occupationEndDateTime = addHours(new Date(`${occupation.endDate} ${occupation.endTime}`), 2);

//                     // Verifica si las fechas se superponen
//                     const isOverlap = !(occupationEndDateTime <= queryStartDateTime || occupationStartDateTime >= queryEndDateTime);

//                     // Si hay superposición, el vehículo está ocupado
//                     if (isOverlap) {
//                         return true;
//                         // result.isOccupied = true;
//                     }

//                     // Verifica si la nueva reserva está entre dos reservas existentes
//                     if (queryStartDateTime >= occupationEndDateTime && queryEndDateTime <= occupationStartDateTime) {
//                         return true;
//                         // result.isOccupied = true;
//                     }
//                 }
//                 return false;
//                 // return result;
//             } else {
//                 // Lógica para cuando se está editando
//                 // const isAlreadyInList = vehicleType.some(available => available.value === vehicle.value);

//                 // if (isAlreadyInList) {
//                     if (
//                         occupation?.value !== vehicle?.value &&
//                         occupation?.startDate &&
//                         occupation?.startTime &&
//                         occupation?.endDate &&
//                         occupation?.endTime
//                     ){

//                         // for(let i=0; lastData?.vehicleType?.length > i;i++){
//                         //     if(lastData?.vehicleType?.[i]?.value === vehicle?.value){
//                         //         console.log("occupation--->", vehicle?.value);
//                         //     }
//                         // }
                        

//                         const occupationStartDateTime = new Date(`${occupation.startDate} ${occupation.startTime}`);
//                         const occupationEndDateTime = addHours(new Date(`${occupation.endDate} ${occupation.endTime}`), 2);

//                         // Verifica si las fechas se superponen
//                         const isOverlap = !(occupationEndDateTime <= queryStartDateTime || occupationStartDateTime >= queryEndDateTime);

//                         // Si hay superposición, el vehículo está ocupado
//                         if (isOverlap) {
//                             return true;
//                         }

//                         // Verifica si la nueva reserva está entre dos reservas existentes
//                         if (queryStartDateTime >= occupationEndDateTime && queryEndDateTime <= occupationStartDateTime) {
//                             return true;
//                         }
//                     }
//                 // }
//                 return false;
//             }
//         });
// }

    return (
        
        <>
        <div>
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-4'>

                    { userInfo?.role !== roleVisit ? ( 

                    <Form.Group className="mb-3" controlId="formNoRegister">
                    <div className="d-inline-flex">
                        <Form.Label>Is the client registered?</Form.Label>
                        <h5 className="required">*</h5>
                    </div>
                    <div className="d-block">
                        <div style={{display: "-webkit-box"}}>
                            <input
                                style={{ margin: "7px" }}
                                type="checkbox"
                                checked={newClient === false}
                                onChange={() => { setNewClient(false); setNewClientData(""); setSendEmail(false);}}
                            />Yes
                            <input
                                style={{ margin: "7px" }}
                                type="checkbox"
                                checked={newClient === true}
                                onChange={() => { setNewClient(true); setClient({ value: "", label: "" }); setSendEmail(false);}}
                            />No
                            { newClient ? (
                                <div style={{marginLeft: "auto"}}>
                                    <Button className={'color-green rounded-circle'} onClick={openClientModal}><BiAddToQueue></BiAddToQueue></Button>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    </Form.Group>

                    ) : ""}


                    { !newClient ? (
                    <Form.Group className="mb-3" controlId="formClientName">
                        <div className="d-inline-flex">
                            { client && (client?.value !== "") && (userInfo?.role !== roleVisit) ? ( <Form.Label><FaInfoCircle className={'mb-1 color-green-info  p-0 rounded-circle'} onClick={showClient} data-toggle="tooltip" data-placement="top" title="Show the client information"></FaInfoCircle>Client name</Form.Label> )
                                : (<Form.Label>Client name</Form.Label>)
                            }
                            <h5 className="required">*</h5>
                        </div>

                        <Select 
                            options={clientList} 
                            value={client}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => changeClient(e)} />

                    </Form.Group>
                    ) : (
                        <Form.Group className="mb-3" controlId="formNewClient">
                        <div className="d-inline-flex">
                            <Form.Label>Unregistered client name</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter the client name"
                            value={newClientData}
                            onChange={(e) => { setNewClientData(e.target.value); setClientName(e.target.value); setClient_Name(e?.label); setSendEmail(false);}}
                            onKeyDown={(e) => keypress(e)}
                        />

                        </Form.Group> 
                    )}

                    { userInfo && userInfo.role !== roleVisit ? (
                    <>
                        { lastData && (lastData?.shuttle?.value == YYC || lastData?.shuttle?.value == YYCShuttleRCR
                            || lastData?.shuttle?.value == YYCLateDeparture || lastData?.shuttle?.value == YXC
                            || lastData?.shuttle?.value == YXCShuttle) ? (

                            sendEmailPending ? (
                                <div className="d-inline-flex" style={{ maxWidth: "5px" }}>
                                    <Button style={{ background: "#173d38" }}> <Loading small={true}></Loading></Button>
                                </div>
                            ) : (
                                sendEmail ? (
                                    <Form.Group className="mb-3" controlId="formShuttle">
                                        <div className="d-inline-flex">
                                            <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Send confirmation</Tooltip>}
                                            >
                                            <Button style={{ background: "#173d38" }} onClick={() => sendBookingConfirmation()}><BiMailSend /></Button>
                                            </OverlayTrigger>
                                        </div>
                                    </Form.Group>
                                ) : ("")
                            )
                        ) : ("")}
                    </>
                    ) : "" }

                    { userInfo && userInfo.role !== roleVisit ? (
                    <>
                        { lastData && (lastData?.shuttle?.value == privateCharter) ? (

                            sendEmailPending ? (
                                <div className="d-inline-flex" style={{ maxWidth: "5px" }}>
                                    <Button style={{ background: "#173d38" }}> <Loading small={true}></Loading></Button>
                                </div>
                            ) : (
                                sendEmail ? (
                                    <Form.Group className="mb-3" controlId="formShuttle">
                                        <div className="d-inline-flex">
                                            <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Send confirmations</Tooltip>}
                                            >
                                            <Button style={{ background: "#173d38" }} onClick={() => sendPrivateCharterConfirmation()}><BiMailSend /></Button>
                                            </OverlayTrigger>
                                        </div>
                                    </Form.Group>
                                ) : ("")
                            )
                        ) : ("")}
                    </>
                    ) : "" }

                    <Form.Group className="mb-3" controlId="formShuttle">
                        <div className="d-inline-flex">
                            <Form.Label>Shuttle type</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select 
                            options={vehicleList} 
                            value={shuttle}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => {
                                onChangeShuttle(startTime, e);
                            }} />

                    </Form.Group> 

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formStartDate">
                                <div className="d-inline-flex">
                                    <Form.Label>Start date</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>

                                <Form.Control type="date"
                                    value={startDate}
                                    readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                    onChange={(e) => { setStartDate(e.target.value); setEndDate(e.target.value); setSendEmail(false); /*changeVehicleAvailability(e.target.value, e.target.value, startTime, endTime);*/ }}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group>
                        </div>

                        <div className='col-md-6'>
                            { shuttle?.label !== YYC && shuttle?.label !== YXC ? (
                                <Form.Group className="mb-3" controlId="formEndDate">
                                <div className="d-inline-flex">
                                    <Form.Label>End date</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>
        
                                <Form.Control type="date"
                                    value={endDate}
                                    readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                    onChange={(e) => { setEndDate(e.target.value); setSendEmail(false); /*changeVehicleAvailability(startDate, e.target.value, startTime, endTime);*/ }}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group>
                            ) : ("") }
                        </div>
                    </div>
                   
                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formStartTime">
                                <div className="d-inline-flex">
                                    <Form.Label>Start time</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>

                                <Form.Control type="time" placeholder="00:00"
                                    value={startTime}
                                    readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                    onChange={(e) => {
                                        setStartTime(e.target.value);
                                        onChangeShuttle(e.target.value, shuttle);
                                        // changeVehicleAvailability(startDate, endDate, e.target.value, endTime);
                                    }}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group> 
                        </div>

                        <div className='col-md-6'>
                            {shuttle?.value !== YYC && shuttle?.value !== YXC ? (<Form.Group className="mb-3" controlId="formEndTime">
                                <div className="d-inline-flex">
                                    <Form.Label>End time</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>

                                <Form.Control type="time" placeholder="00:00"
                                    value={endTime}
                                    readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                    onChange={(e) => { 
                                        setEndTime(e.target.value); 
                                        setSendEmail(false);                                
                                        // changeVehicleAvailability(startDate, endDate, startTime, e.target.value);
                                    }}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </Form.Group>) : (<></>)}
                        </div>

                    </div>

                    <Form.Group className="mb-3">
                    <div className={vehicleType ? (vehicleType?.length > 0 ? ("retail-container") : ("")) : ("")}>
                        <div className='flex d-flex justify-content-between mb-2'>
                            <div className="d-inline-flex">
                                <Form.Label>Vehicle Type</Form.Label>
                                {/* <h5 className="required">*</h5> */}
                            </div>
                            { userInfo?.role !== roleVisit ? (
                                <Button className={'color-green rounded-circle'} onClick={addInputFieldVehicleType}><BiAddToQueue></BiAddToQueue></Button>
                            ) : "" }

                        </div>
                        <div className={vehicleType ? (vehicleType?.length > 0 ? ("retail-scroll-container") : ("")) : ("")}>
                            {vehicleType.map((vehicle, index) => (
                                <div key={index} className="input-group mb-3">
                                    {/* <span className="input-group-text">{`Extra ${index + 1}`}</span> */}
                                    
                                    <Select 
                                        options={shuttleList} 
                                        className="form-control"
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        menuPortalTarget={document.body}
                                        id={`renta-${index + 1}`}
                                        value={vehicle}
                                        isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                                        onChange={(e) => handleInputChangeVehicleType(index, e)} />
                                    
                                    { userInfo?.role !== roleVisit ? (
                                        <Button className={'input-group-text'} style={{ marginLeft: 3, background: "#f4673c", zIndex: 0 }} onClick={() => removeFieldsVehicleType(index)}><AiOutlineDelete ></AiOutlineDelete></Button>
                                    ) : "" }

                                </div>
                            ))}
                        </div>
                    </div>

                    </Form.Group>

                    <div className='row'>
                        <div className='col-md-6'>
                        <Form.Group className="mb-3" controlId="formPickUp">
                            <div className="d-inline-flex">
                            <Form.Label>Pick up</Form.Label>
                                <h5 className="required">*</h5>
                            </div>

                            <Select 
                                options={placeList} 
                                value={pickUp}
                                isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                                onChange={(e) => { setPickUp(e); setSendEmail(false); }} />
                        </Form.Group> 
                        </div>

                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formDropOff">
                                <div className="d-inline-flex">
                                <Form.Label>Drop off</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>

                                <Select 
                                    options={placeList} 
                                    value={dropOff}
                                    isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                                    onChange={(e) => { setDropOff(e); setSendEmail(false); }} />
                            </Form.Group>
                        </div>
                    </div>

                    { pickUp?.label === "Fernie" ? (
                        <Form.Group className="mb-3" controlId="formPickUpAddress">
                        <div className="d-inline-flex">
                            <Form.Label>Pick Up Fernie address</Form.Label>
                        </div>

                        <Form.Control as="textarea" rows={3} placeholder="Enter a pick up address"
                            value={pickUpAddress}
                            readOnly={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { setPickUpAddress(e.target.value); setSendEmail(false); }}
                        />

                    </Form.Group>
                    ) : ("")}

                    { dropOff?.label === "Fernie" ? (
                        <Form.Group className="mb-3" controlId="formDropOffAddress">
                        <div className="d-inline-flex">
                            <Form.Label>Drop off Fernie address</Form.Label>
                        </div>

                        <Form.Control as="textarea" rows={3} placeholder="Enter a drop off address"
                            value={dropOffAddress}
                            readOnly={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { setDropOffAddress(e.target.value); setSendEmail(false); }}
                        />

                    </Form.Group>
                    ) : ("")}

                    <Form.Group className="mb-3" controlId="formNop">
                        <div className="d-inline-flex">
                            <Form.Label>Number of people</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="number" placeholder="Enter the number of people"
                            value={nop}
                            readOnly={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { setNop(e.target.value); 
                                automaticTotal(shuttle,e.target.value,taxes,extraCost,fuelSurcharge,discount, tipAmount, cardSurcharge); 
                                setSendEmail(false);}}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group> 

                </div>

                <div className='col-md-4'>
                    
                    <Form.Group className="mb-3" controlId="formTaxRate">
                        <div className="d-inline-flex">
                            <Form.Label>Tax rate</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select 
                            options={taxList} 
                            value={taxes}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => {
                                setTaxes(e); (amount ? calculateTotal(amount,e, extraCost, fuelSurcharge, discount, tipAmount, cardSurcharge) : 
                                automaticTotal(shuttle,nop,e,extraCost,fuelSurcharge,discount, tipAmount, cardSurcharge));/*calculateTotal(amount, e, extraCost)*/
                                setSendEmail(false);
                            }} />

                    </Form.Group> 

                    <Form.Group className="mb-3" controlId="formDiscount">
                        <div className="d-flex">
                            <Form.Label>Discount</Form.Label>
                        </div>

                        <div className="d-flex">
                            <div style={discount?.value !== "" ? {width: "90%"} : {width: "100%"}}>
                                <Select 
                                    options={discountList} 
                                    value={discount}
                                    isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                                    onChange={(e) => {
                                        setDiscount(e); (amount ? calculateTotal(amount,taxes, extraCost, fuelSurcharge, e, tipAmount, cardSurcharge) : 
                                        automaticTotal(shuttle,nop,taxes,extraCost,fuelSurcharge,e, tipAmount, cardSurcharge));
                                        setSendEmail(false);
                                    }}
                                />
                            </div>
                            { discount?.value !== "" ? (
                                <div style={{width: "10%"}}>
                                    { userInfo?.role !== roleVisit ? (
                                        <Button className={'input-group-text'} style={{ marginLeft: 3, background: "#f4673c" }} 
                                        onClick={() => { 
                                            setDiscount({ value: "", label: "" }); 
                                            (amount ? calculateTotal(amount, taxes, extraCost, fuelSurcharge, { value: "", label: "" }, tipAmount, cardSurcharge) : 
                                            automaticTotal(shuttle, nop, taxes, extraCost, fuelSurcharge, { value: "", label: "" }, tipAmount, cardSurcharge));
                                            setSendEmail(false);
                                        }}><AiOutlineDelete ></AiOutlineDelete></Button> 
                                    ) : "" }
                                </div>
                            ) : (<></>)}
                            
                        </div>
                      
                    </Form.Group> 

                    <Form.Group className="mb-2" controlId="formFuelSurcharge">
                    <div className="d-inline-flex">
                        <Form.Label>Add Fuel Surcharge?</Form.Label>
                        <h5 className="required">*</h5>
                    </div>
                    <div className="d-block">
                        <input
                            style={{ margin: "7px" }}
                            disabled={ userInfo?.role !== roleVisit ? false : true } 
                            type="checkbox"
                            checked={fuelSurcharge === true}
                            onChange={() => { 
                                setFuelSurcharge(true); 
                                (amount ? calculateTotal(amount,taxes, extraCost, true, discount, tipAmount, cardSurcharge) : 
                                automaticTotal(shuttle,nop,taxes,extraCost,true,discount,tipAmount, cardSurcharge)) ;/*calculateTotal(amount,taxes,extraCost,true);*/ 
                                setSendEmail(false);
                            }}
                        />Yes
                        <input
                            style={{ margin: "7px" }}
                            disabled={ userInfo?.role !== roleVisit ? false : true } 
                            type="checkbox"
                            checked={fuelSurcharge === false}
                            onChange={() => { 
                                setFuelSurcharge(false); 
                                setFuelSurchargeAmount(0); 
                                (amount ? calculateTotal(amount,taxes, extraCost, false, discount, tipAmount, cardSurcharge) : 
                                automaticTotal(shuttle,nop,taxes,extraCost,false,discount,tipAmount,cardSurcharge));/*calculateTotal(amount,taxes,extraCost,false);*/ 
                                setSendEmail(false);
                            }}
                        />No
                    </div>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formFuelSurcharge">
                        <div className="d-inline-flex">
                            <Form.Label>                        
                                {fuelSurcharge ? ("The Fuel Surcharge is " + fuelSurchargePercentage +"%") : ("")}
                            </Form.Label>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formCardSurcharge">
                        <div className="d-inline-flex">
                            <Form.Label>Add Card Surcharge?</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={cardSurcharge === true}
                                onChange={() => { 
                                    setCardSurcharge(true); 
                                    (amount ? calculateTotal(amount,taxes, extraCost, fuelSurcharge, discount, tipAmount, true) : 
                                    automaticTotal(shuttle,nop,taxes,extraCost,fuelSurcharge,discount,tipAmount, true));
                                    setSendEmail(false);
                                }}
                            />Yes
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={cardSurcharge === false}
                                onChange={() => { 
                                    setCardSurcharge(false); 
                                    setCardSurchargeAmount(0); 
                                    (amount ? calculateTotal(amount,taxes, extraCost, fuelSurcharge, discount, tipAmount, false) : 
                                    automaticTotal(shuttle,nop,taxes,extraCost,fuelSurcharge,discount,tipAmount, false)); 
                                    setSendEmail(false);
                                }}
                            />No
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formCardSurcharge">
                        <div className="d-inline-flex">
                            <Form.Label>                        
                                {cardSurcharge ? ("The Card Surcharge is " + cardSurchargePercentage +"%") : ("")}
                            </Form.Label>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <div className="retail-container">
                        <div className='flex d-flex justify-content-between mb-2'>
                            <div className="d-inline-flex">
                                <Form.Label>Extra cost</Form.Label>
                            </div>
                            { userInfo?.role !== roleVisit ? ( 
                                <Button className={'color-green rounded-circle'} onClick={addInputFieldExtraCost}><BiAddToQueue></BiAddToQueue></Button>
                            ) : ""}

                        </div>
                        <div className="retail-scroll-container">
                            {extraCost.map((extraCost, index) => (
                                <div key={index} className="input-group mb-3">
                                    {/* <span className="input-group-text">{`Extra ${index + 1}`}</span> */}
                                    
                                    <Select 
                                        options={extraCostList} 
                                        className="form-control"
                                        id={`extra-${index + 1}`} 
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        menuPortalTarget={document.body} 
                                        value={extraCost}
                                        onChange={(e) => handleInputChangeExtraCost(index, e,document.getElementById(`idExtra-${index}`).value)} />

                                    <Form.Control type="text" key={index} id={`idExtra-${index}`}
                                        placeholder={extraCost?.quantity}
                                        style={{maxWidth:"20%", textAlign:"center"}}
                                        onChange={(e) => handleInputChangeExtraCost(index,extraCost,e.target.value)}
                                        onKeyDown={(e) => keypress(e)}/> 
                                    
                                    { userInfo?.role !== roleVisit ? ( 
                                        <Button className={'input-group-text'} style={{ marginLeft: 3, background: "#f4673c" }} onClick={() => removeFieldsExtraCost(index)}><AiOutlineDelete ></AiOutlineDelete></Button>
                                    ) : ""}

                                </div>
                            ))}
                        </div>
                    </div>
                    </Form.Group>

                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formAmount">
                                <div className="d-inline-flex">
                                    <Form.Label>Amount</Form.Label>
                                    <h5 className="required">*</h5>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={amount}
                                        readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                        onChange={(e) => { calculateTotal(e.target.value,taxes, extraCost, fuelSurcharge, discount, tipAmount, cardSurcharge); setSendEmail(false); }}
                                        onKeyDown={(e) => keypress(e)}
                                    />
                                </div>
                            </Form.Group> 
                        </div>

                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="formTip">
                                <div className="d-inline-flex">
                                    <Form.Label>Tip</Form.Label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={tipAmount}
                                        readOnly={ userInfo?.role !== roleVisit ? false : true } 
                                        onChange={(e) => { 
                                            setTipAmount(e.target.value); 
                                            ( amount ? calculateTotal(amount, taxes, extraCost, fuelSurcharge, discount, (e.target.value ? e.target.value : 0), cardSurcharge) : 
                                            automaticTotal(shuttle, nop, taxes, extraCost, fuelSurcharge, discount, (e.target.value ? e.target.value : 0), cardSurcharge)) 
                                            setSendEmail(false);
                                        }}
                                        onKeyDown={(e) => keypress(e)}
                                    />    
                                </div>

                            </Form.Group> 
                        </div>

                    </div>

                    <div className='row'>

                        { fuelSurchargeAmount > 0  ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formFuelSurchargeAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Fuel Surcharge</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(fuelSurchargeAmount).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            // onChange={(e) => setFuelSurchargeAmount(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        /> 
                                    </div>
                                </Form.Group> 
                            </div>
                        ) : ("")}

                        { cardSurchargeAmount > 0  ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formCardSurchargeAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Card Surcharge</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(cardSurchargeAmount).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            // onChange={(e) => setCardSurchargeAmount(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        /> 
                                    </div>
                                </Form.Group> 
                            </div>
                        ) : ("")}
                          
                    </div>

                    <div className='row'>

                        { discountAmount ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formDiscountAmount">
                                    <div className="d-inline-flex">
                                        <Form.Label>Discount amount</Form.Label>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <span style={{ marginRight: '5px' }}>$</span>
                                        <Form.Control type="number" placeholder="0"
                                            value={parseFloat(discountAmount).toFixed(2).padStart(4, '0')}
                                            readOnly
                                            // onChange={(e) => setDiscountAmount(e.target.value)}
                                            onKeyDown={(e) => keypress(e)}
                                        /> 
                                    </div>  
                                </Form.Group>
                            </div>
                        ) : ("") }

                        { extraAmount > 0 ? (
                            <div className='col-md-6'>
                                <Form.Group className="mb-3" controlId="formExtraAmount">
                                <div className="d-inline-flex">
                                    <Form.Label>Extra amount</Form.Label>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span style={{ marginRight: '5px' }}>$</span>
                                    <Form.Control type="number" placeholder="0"
                                        value={parseFloat(extraAmount).toFixed(2).padStart(4, '0')}
                                        readOnly
                                        // onChange={(e) => setExtraAmount(e.target.value)}
                                        onKeyDown={(e) => keypress(e)}
                                    />    
                                </div>
                                </Form.Group>

                            </div>
                        ) : ("")} 

                    </div>

                </div>
                <div className='col-md-4'> 

                <div className='row'>
                    <div className='col-md-6'>
                        <Form.Group className="mb-3" controlId="formTaxAmount">
                            <div className="d-inline-flex">
                                <Form.Label>Tax amount</Form.Label>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span style={{ marginRight: '5px' }}>$</span>
                                <Form.Control type="number" placeholder="0"
                                    value={parseFloat(taxAmount).toFixed(2).padStart(4, '0')}
                                    readOnly
                                    // onChange={(e) => setTaxAmount(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                />  
                                </div>
                        </Form.Group>
                    </div>

                    <div className='col-md-6'>
                        <Form.Group className="mb-3" controlId="formTotal">
                            <div className="d-inline-flex">
                                <Form.Label>Total</Form.Label>
                            </div>
                            
                            <div className='d-flex align-items-center'>
                                <span style={{ marginRight: '5px' }}>$</span>
                                <Form.Control
                                    type="text"
                                    placeholder="0.00"
                                    value={parseFloat(total).toFixed(2).padStart(4, '0')}
                                    readOnly
                                    onKeyDown={(e) => keypress(e)}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Form.Group> 
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <Form.Group className="mb-3" controlId="formAmountPaid">
                            <div className="d-inline-flex">
                                <Form.Label>Amount paid</Form.Label>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span style={{ marginRight: '5px' }}>$</span>
                                <Form.Control type="number" placeholder="0"
                                    value={parseFloat(amountPaid).toFixed(2).padStart(4, '0')}
                                    readOnly
                                    // onChange={(e) => setAmountPaid(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </div>
                        </Form.Group> 
                    </div>
                    <div className='col-md-6'>
                        <Form.Group className="mb-3" controlId="formBalance">
                            <div className="d-inline-flex">
                                <Form.Label>Balance</Form.Label>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span style={{ marginRight: '5px' }}>$</span>
                                <Form.Control type="number" placeholder="0"
                                    value={parseFloat(balance).toFixed(2).padStart(4, '0')}
                                    readOnly
                                    // onChange={(e) => setBalance(e.target.value)}
                                    onKeyDown={(e) => keypress(e)}
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>

                    <Form.Group className="mb-3" controlId="formDriverName">
                        <div className="d-inline-flex">
                            <Form.Label>Driver Name</Form.Label>
                            {/* <h5 className="required">*</h5> */}
                        </div>

                        <Form.Control type="text" placeholder="Enter the driver name"
                            value={driverName}
                            readOnly={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { setDriverName(e.target.value); setSendEmail(false);}}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group> 

                    <Form.Group className="mb-3" controlId="formInvoicedVia">
                        <div className="d-inline-flex">
                            <Form.Label>Payment Type</Form.Label>
                            {/* <Form.Label>Invoice Via</Form.Label>  //Asi se llamaba antes, se cambio para que el usuario pudiera ingresar una informacion mas clara*/} 
                        </div>

                        <Select 
                            options={paymentTypes} 
                            value={invoicedVia}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { setInvoicedVia(e); setSendEmail(false); }} />
                  
                    </Form.Group>

                    { urlItinerary ? (
                        <Form.Group className="mb-3" controlId="formItinerary">
                            <div className='row'>
                                <div className=''>
                                    <div className="d-inline-flex">
                                        <Form.Label>Itinerary</Form.Label>
                                    </div>
                                </div>
                                <div className=''>
                                    <a 
                                        className="transparent-button p-1 mx-1" 
                                        onClick={() => { window.open(urlItinerary, "_blank"); }}
                                        title="See the document"
                                    ><GrDocumentPdf></GrDocumentPdf></a> 
                                </div>
                            </div>

                        </Form.Group>
                    ) : ("")}

                    <Form.Group className="mb-3" controlId="formSearched">
                        <div className="d-inline-flex">
                            <Form.Label>Searched</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={searched === false}
                                onChange={() => { changeSearched(false); setSendEmail(false); }}
                            />No
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={searched === true}
                                onChange={() => { changeSearched(true); setSendEmail(false); }}
                            />Yes
                        </div>

                    </Form.Group> 

                    { searched === true ? 
                    (
                    <Form.Group className="mb-3" controlId="formSearchedOptions">
                        <div className="d-inline-flex">
                            <Form.Label>Searched Options</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select 
                            options={searchedList} 
                            value={searchedOptions}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { setSearchedOptions(e); setSendEmail(false); }} />

                    </Form.Group>
                    ) : ("")} 

                    <Form.Group className="mb-3" controlId="formBookingType">
                        <div className="d-inline-flex">
                            <Form.Label>Transport type</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={bookingType === "Directly"}
                                onChange={() => { changeBookingType("Directly"); setSendEmail(false); }}
                            />Directly
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={bookingType === "Agent"}
                                onChange={() => { changeBookingType("Agent"); setSendEmail(false); }}
                            />Agent
                        </div>

                    </Form.Group> 

                    <Form.Group className="mb-3" controlId="formNotificationAfter">
                        <div className="d-inline-flex">
                            <Form.Label>Notification after service?</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <div className="d-block">
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={notificationAfter === false}
                                onChange={() => { changeNotificationAfter(false); setSendEmail(false); }}
                            />No
                            <input
                                style={{ margin: "7px" }}
                                disabled={ userInfo?.role !== roleVisit ? false : true } 
                                type="checkbox"
                                checked={notificationAfter === true}
                                onChange={() => { changeNotificationAfter(true); setSendEmail(false); }}
                            />Yes
                        </div>

                    </Form.Group> 

                    { bookingType === "Agent" ? 
                    (
                    <Form.Group className="mb-3" controlId="formAgents">
                        <div className="d-inline-flex">
                            <Form.Label>Agent</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Select 
                            options={agentsList} 
                            value={agents}
                            isDisabled={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { setAgents(e); setSendEmail(false); }} />

                    </Form.Group>
                    ) : ("")} 

                    <Form.Group className="mb-3" controlId="formNote">
                        <div className="d-inline-flex">
                            <Form.Label>Note</Form.Label>
                            {/* <h5 className="required">*</h5> */}
                        </div>

                        <Form.Control as="textarea" 
                            rows={12} 
                            placeholder="Enter a note"
                            value={note}
                            readOnly={ userInfo?.role !== roleVisit ? false : true } 
                            onChange={(e) => { setNote(e.target.value); setSendEmail(false);}}
                        />

                    </Form.Group>
                </div>

                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => {{ setModal(false); setDuplicate(null); } }}>Cancel</Button>
                { userInfo?.role !== roleVisit ? (
                    lastData?.refund && lastData?.balance == 0 ? (
                        ""
                    ) : (
                        <Button style={{ background: "#173d38" }} className='button-save' onClick={() => {                    
                            var data = {
                                id,
                                idClient,
                                client,
                                clientName,
                                client_name,
                                driverName,
                                invoicedVia,
                                newClientData,
                                newClient,
                                shuttle,
                                vehicleType,
                                refund,
                                refundAmount,
                                tipAmount,
                                tipAmountPaid,
                                notificationAfter,
                                // leadStatus,
                                searched,
                                searchedOptions,
                                agentCommission,
                                pickUp,
                                pickUpAddress,
                                dropOff,
                                dropOffAddress,
                                nop,
                                paid,
                                showInvoice,
                                startDate,
                                endDate,
                                startTime,
                                endTime,
                                amount,
                                amountPaid,
                                taxAmount,
                                extraAmount,
                                total,
                                bookingType,
                                agents,
                                balance,
                                // paymentLink,
                                note,
                                taxes,
                                extraCost,
                                fuelSurcharge,
                                fuelSurchargeAmount,
                                fuelSurchargePercentage,
                                cardSurcharge,
                                cardSurchargeAmount,
                                cardSurchargePercentage,
                                discount,
                                discountAmount,
                                vehicleOccupation
                                // historical_matriz
                            };
                            checkData(data);
                        }

                        }>
                            Save
                        </Button>
                    )
                ) : ("")}
            </div>
        </form>
        
        <ModalC
            show={modalClient}
            onHide={() => setModalClient(false)}>
            <FormClient title={titleModalClient} lastData={{client: newClientData}} textButton={titleModalClient} action={actionClient} setModal={setModalClient}></FormClient>
        </ModalC>
        </div>
        </>

    );
}

export default FormConfig;